import _ from 'lodash'
import {TCampaignType} from '../core/type'


export const de = {
  translation: {
    errorBoundary: {
      siteWasNotFound: 'The site was not found',
      pageDoesNotExist: 'Die von Ihnen gesuchte Seite existiert leider nicht oder der Link ist veralted.',
    },
    header: {
      menu: {
        editProfile: 'Profil bearbeiten',
        changePassword: 'Passwort ändern',
        logout: 'Logout',
      },
      navigation: {
        campaign: 'Kampagnen',
        client: 'Kunden',
        user: 'Benutzer',
        tenants: 'Tenants',
      },
    },
    footer: {
      termsAndConditions: 'Terms and Conditions',
      privacyPolicy: 'Datenschutzerklärung',
      imprint: 'Impressum',
    },
    notFound: {
      404: 404,
      siteWasNotFound: 'The site was not found',
      pageDoesNotExist: 'Die von Ihnen gesuchte Seite existiert leider nicht oder der Link ist veralted.',
    },
    errorHandling: {
      400: {
        header: 'Bad request',
        body: 'The server cannot or will not process the request due to something that is perceived to be a client error (e.g., malformed request syntax, invalid request message framing, or deceptive request routing).',
      },
      401: {
        header: 'Unauthorized',
        body: 'The request has not been applied because it lacks valid authentication credentials for the target resource.',
      },
      402: {
        header: 'PAYMENT REQUIRED',
        body: 'Reserved for future use.',
      },
      403: {
        header: 'Forbidden',
        body: 'The server understood the request but refuses to authorize it.',
      },
      404: {
        header: 'Not found',
        body: 'The origin server did not find a current representation for the target resource or is not willing to disclose that one exists.',
      },
      405: {
        header: 'Method not allowed',
        body: 'The method received in the request-line is known by the origin server but not supported by the target resource.',
      },
      500: {
        header: 'Internal server error',
        body: 'The server encountered an unexpected condition that prevented it from fulfilling the request.',
      },
      501: {
        header: 'Not implemented',
        body: 'The server does not support the functionality required to fulfill the request.',
      },
      502: {
        header: 'Bad gateway',
        body: 'The server, while acting as a gateway or proxy, received an invalid response from an inbound server it accessed while attempting to fulfill the request.',
      },
      503: {
        header: 'Service unavailable',
        body: 'The server is currently unable to handle the request due to a temporary overload or scheduled maintenance, which will likely be alleviated after some delay.',
      },
      504: {
        header: 'Gateway timeout',
        body: 'The server, while acting as a gateway or proxy, did not receive a timely response from an upstream server it needed to access in order to complete the request.',
      },
      505: {
        header: 'HTTP version not supported',
        body: 'The server does not support, or refuses to support, the major version of HTTP that was used in the request message.',
      },
    },
    appearance: {
      //     {t('appearance.')}
      theme: 'Theme',
      displayMessage: 'Nachricht anzeigen',
      hideMessage: 'Nachricht ausblenden',
      validation: {
        invalidImageType:
          'Ungültiges Bildformat. Zulässige Typen sind: {{allowed}}, bereitgestellte Format ist {{provided}}. ',
        maxImageSize: 'Bildgröße ist größer als erlaubt {{value}}',
        minImageSize: '',
        maxImageWidth: 'Die Bildbreite sollte nicht größer sein als {{value}}',
        minImageWidth: 'Die Bildbreite sollte nicht kleiner sein als {{value}}',
        minImageHeight: 'Die Bildhöhe sollte nicht kleiner sein als {{value}}',
        maxImageHeight: 'Die Bildhöhe sollte nicht größer sein als {{value}}',
      },
    },
    auth: {
      // {t('auth.')}
      logInNow: 'Jetzt einloggen',
      registration: {
        submitRegistration: 'Registrierung absenden',
        registerInDomain: 'Login-Bereich anmelden.',
      },
      changePassword: 'Change Password',
      restorePassword: 'Passwort zurückgesetzt',
      passwordResetedSuccessfully: 'Dein Passwort wurde erfolgreich zurückgesetzt.',
      confirmEmail: 'Bestätigungs-E-Mail',
      login: 'Login',
      register: 'Registrieren',
      registerInAdonceForFree: 'Melde dich jetzt kostenlos bei AdOnce an!',
      iAcceptThis: 'Hiermit akzeptiere ich die',
      and: 'und die',
      yeewGMBH: 'der Yeew GmbH',

      areYouRegistered: ' Bist Du bereits bei AdOnce angemeldet? Dann kannst Du Dich direkt über unseren',
      loginInNow: 'Jetzt einloggen',
      linkSent: 'LINK SENDEN',
      checkYourEmailBox: 'Postfach überprüfen',
      forgetPassword: 'Passwort vergessen?',
      registerForFree: 'Kostenlos registrieren',
      registerHereForFree: 'Hier kostenlos registrieren!',
      // register: 'Зареєструйтеся в області входу.',
      passwordChanedDescription:
        'Die E-Mail zum Zurücksetzen des Passworts wurde versendet. Bitte überprüfe dein Postfach und klicke auf den Link in der E-Mail.',
      registrationDescription:
        'Gib die E-Mail-Adresse ein, mit der Du Dein Konto erstellt hast. Wir senden Dir einen Link zum Zurücksetzen Deines Passwortes zu.',
      registeredSuccessfully:
        'Bestätigungs-E-Mail wurde gesendet. Sie müssen Ihre E-Mail bestätigen, um Zugriff auf das System zu erhalten',
      emailConfirmed: 'E-Mail bestätigt',
      emailConfirmedDescription: 'Ihre E-Mail wurde bestätigt und Sie können sich jetzt anmelden',
      invalidLink: 'Ungültiger Link',
      invalidLinkDescription:
        'Dieser Link ist nicht mehr gültig, bitte gehen Sie zur Anmeldeseite und versuchen Sie sich anzumelden',
      emailConfirmation: 'Email Bestätigung',
      confirmationIsSent: 'Bestätigungs-E-Mail wurde gesendet',
      sendConfirmationAgain: 'Bestätigung erneut senden',
      requestingConfirmation: 'E-Mail-Bestätigungslink wird angefordert',
      pleaseWait: 'Warten Sie mal...',
      errorCodes: {
        '2001': 'Benutzer ist gesperrt. Wenden Sie sich zum Entsperren an den Admin.',
        '2004':
          'Sie müssen Ihre E-Mail bestätigen, um Zugang zum System zu erhalten. Bitte folgen Sie dem Link in der erhaltenen E-Mail in Ihrem Posteingang. <a href="/new/auth/confirm-email/request-token/{{email}}">Bestätigungsmail erneut senden</a>',
        '2002': 'E-Mail oder Passwort ist ungültig.',
      },
      validation: {
        emailInvalid: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
        passwordsDoNotMatch: 'Passwörter stimmein nicht überein',
        emailsDoNotMatch: 'Emails stimmen nicht überein',
        passwordIsShort: 'Das passwort ist zu kurz',
        passwordIsLong: 'Das passwort ist zu lang',
        email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
        emailIsRequired: 'Email ist erforderlich',
      },
      fields: {
        name: 'Name',
        email: 'Email',
        password: 'Passwort',
        repeatEmail: 'E-Mail-Adresse bestätigen',
        repeatPassword: 'Passwort bestätigen',
      },
    },
    common: {
      reportButton: 'PROBLEM MELDEN',
      poweredBy: 'Angetrieben von AdOnce Nachricht',
      uploadLogoImage: 'Logo hochladen',
      rowsPerPage: 'Zeilen pro Seite',
      noRowsToDisplay: 'Es sind keine Zeilen zum Anzeigen vorhanden',
      searchBy: 'Suche nach',
      save: 'Speichern',
      saveChanges: 'Änderungen speichern',
      cancel: 'Stornieren',
      delete: 'Löschen',
      snackbars: {
        deletedSuccessfully: '',
        savedSuccessfully: 'Erfolgreich gespeichert',
        errorOccurred: 'Ein Fehler ist aufgetreten',
      },
      userStatus: {
        active: 'Aktiv',
        notVerified: 'Nicht verifiziert',
      },
      gender: {
        male: 'Herr',
        female: 'Frau',
        gender: 'Geschlecht',
        all: 'Alle',
        men: 'Männer',
        women: 'Frauen',
      },
      age: {
        age: 'Alter',
      },
      validation: {
        required: '{{field}} ist erforderlich',
        isRequired: 'erforderlich',
        positive: '{{field}} should be positive',
        minValue: 'Sollte gleich oder größer als {{minValue}} sein',
        maxValue: 'Muss gleich oder kleiner sein als {{maxValue}}',
        lengthMin: 'Dieses Feld muss mindestens {{length}} Zeichen lang sein',
        lengthMax: 'Dieses Feld darf höchstens {{length}} Zeichen lang sein',
        invalidTypeProvided: 'Ungültiger Dateityp angegeben: {{provided}}, erlaubte Typen sind: {{allowed}}',
        invalidMaxFileSizeProvided:
          'Die Dateigröße sollte nicht größer sein als {{allowed}}, {{provided}} bereitgestellt',
        locationsWereNotProvided: 'Bitte Anschrift, PLZ, Ort oder Region auswählen.',
      },
    },
    clients: {
      client: 'Kunde',
      clients: 'Kunden',
      editClient: 'Edit Client',
      createClient: 'Neuen Kunden anlegen',
      createClientPageHeader: 'Neu Kunden',
      returnToAllClients: 'Zurück zu allen Kunden',
      chooseFile: 'Datei wählen',
      saveToHavePreview: 'Speichern Sie die Änderungen, um eine Dateivorschau zu erhalten',
      fields: {
        countryCode: 'Land',
        zip: 'PLZ',
        city: 'Stadt',
        address: 'Straße Hausnummer',
        phone: 'Telefonnummer',
        googleAdsCustomerId: 'Google Ads ID',
        registrationNumber: 'Registrationsnummer',
        tradeLicenceNumber: 'Gewerbescheinnummer',
        vatNumber: 'VAT nummer',
        dunsNumber: 'DUNS nummer',
        name: 'Firma',
        email: 'Email',
        createdOn: 'Erstellt am',
        externalId: 'Kundennummer',
      },
      validation: {
        dunsNumber: 'Nur Ziffern für DUNS-Nummer erlaubt',
        name: 'Firma ist erforderlich',
        phone: 'Telefonnummer ist erforderlich',
        phoneInvalid: 'Die Telefonnummer sollte aus Ziffern bestehen',
        emailInvalid: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
        externalId: 'Kundennummer',
      },
    },
    tenants: {
      allTenants: 'Filtern nach Tenant',
      firstAdmin: 'Erster Admin',
      firstAdminDescription:
        'Bitte fügen Sie die Details des Benutzers hinzu, der als erster Administrator dieses neuen Mandanten erstellt werden soll. Dieser Benutzer muss die Einladung annehmen und seine E-Mail-Adresse bestätigen, bevor er das Tool mit Administratorrechten aufrufen kann',
      selectBrand:
        'Wählen Sie aus, welcher Markenname plattformübergreifend verwendet werden soll und welches Farbthema verwendet werden soll',
      customization: 'Anpassung',
      customTheme: 'Benutzerdefiniert Thema',
      adonceColors: 'AdOnce Farben',
      backToAllTenants: 'Zurück zu allen Tenants',
      customLogoHeading: 'Marke zu verwenden',
      defaultLogo: 'AdOnce',
      customLogo: 'Eigene Marke',
      setAdServer: 'Ad-Server einrichten',
      setAdServerFor: 'Ad-Server einrichten für',
      setAdServerDescription: 'Dieses Setup kann nur während der Tenanterstellung geändert werden.',
      setAdServerNotes:
        '\n' +
        'Bitte beachten Sie, dass Ad-Server nur einmal ausgewählt werden können. Nach der Erstellung eines Mandanten können Sie die Ad-Server nicht mehr ändern.',
      createTenant: 'Erstellen neu Tenant',
      createTenantPageHeader: 'Neu Tenant',
      editTenant: 'Einzelheiten',
      returnToTenants: 'Zurück zu allen Mietern',
      deleteTenant: 'Mieter löschen',
      deleteWarningText: 'Sind Sie sicher, dass Sie löschen möchten',
      deletedSuccessfully: '"{{name}}" Tenant erfolgreich gelöscht',
      adServers: {
        nativeAds: 'Native Ads',
        displayAds: 'Display Ads',
      },
      budgets: {
        tenant: 'Tenant',
        pageHeader: 'Budget einrichten',
        googleMarginDescriptionCaption:
          'Legen Sie die AdOnce und Partnermarge für jeden Bereich des Kampagnenbudgets fest.',
        googleImpessionDescriptionCaption:
          'Legen Sie 0 Zielimpressionen für den Budgetbereich fest, den Sie manuell in Google Ads aktualisieren möchten.',
        SASubheaderCaption: 'Legen Sie die AdOnce und Partnermarge für jeden Bereich des Kampagnenbudgets fest.',
        campaignsWithEndDate: 'Kampagnen mit einem Enddatum',
        campaignsWithoutEndDate: 'Kampagnen ohne Enddatum',
        totalBudget: 'Gesamtbudget',
        adonceMargin: 'Marge von AdOnce und Partnern',
        targetImpressions: 'Target Impressions',
        adonceCPMMargin: 'AdOnce CPM-Marge',
        addBudgetRange: 'Budgetbereich hinzufügen',
        cannotAddMoreBudgets: 'Die Tabelle kann maximal {{max}} Budgets enthalten',
        budgetAlreadyExists: 'Dieses Budget existiert bereits',
        targetImpressionsMaxValueError: 'Dieses Feld darf höchstens 12 Zeichen lang sein',
        deleteBudgetHeader: 'Budgetbereich löschen ?',
        deleteBudgetBody:
          "Möchten Sie den Budgetbereich  '{{name}}' und seine Einstellungen wirklich löschen? Dies ist unumkehrbar.",
        cancel: 'Abbrechen',
        deleteBudget: 'Budget löschen',
        failedToLoad: 'Tenant Target Impressions konnten nicht geladen werden',
        optimization: {
          'CPC (enhanced)': 'Auto-optimierter CPC',
          'Maximize clicks': 'Klicks maximieren',
          'Maximize conversions': 'Conversions maximieren',
          'Viewable CPM': 'Sichtbarer CPM',
          title: 'Optimierung',
          description:
            'Legen Sie Standardwerte für Gebotsstrategien fest. Der Wert der Gebotsstrategie kann bei der Kampagnenerstellung bearbeitet werden.',
          default: 'Standard',
          strategy: 'Gebotsstrategie',
          value: 'Wert',
          cpcParameter: 'Maximales CPC-Gebot',
          cpmParameter: 'Maximaler CPM (Sichtbare)',
          conversionsParameter: 'Ziel-CPA',
        },
      },
      listContextMenu: {
        editTenant: 'Einzelheiten',
        copyCampaign: 'Kampagne kopieren',
        customizeAppearance: 'Aussehen anpassen',
        setAdServer: 'Ad-Server einrichten',
        deleteTenant: 'Mieter löschen',
        budgetSetup: 'Budget einrichten',
      },
      fields: {
        id: 'ID',
        name: 'Name',
        email: 'Email',
        domain: 'Domain',
        googleId: 'Google ID',
        googleAdsCustomerId: 'Google Ads ID',
        countryCode: 'Land',
        zip: 'PLZ',
        city: 'Stadt',
        address: 'Straße Hausnummer',
        phone: 'Telefonnummer',
        emailHelpText: 'E-Mail-Benachrichtigungen und Berichte werden an diese E-Mail-Adresse gesendet.',
      },
      validation: {
        domainShouldBeUnique: 'Domain should be unique',
        noAdServerSelected: 'Mindestens einer sollte ausgewählt werden',
        name: 'Name ist erforderlich',
        email: 'Email ist erforderlich',
        city: 'Stadt ist erforderlich',
        phone: 'Telefonnummer ist erforderlich',
        phoneInvalid: 'Die Telefonnummer sollte aus Ziffern bestehen ',
        googleAdsCustomerIdInvalidLength: 'Die Google ID muss 10 Ziffern lang sein',
        googleAdsCustomerId: 'Google ID ist erforderlich',
        googleAdsCustomerIdInvalid: 'Die Google-ID-Nummer sollte aus Ziffern bestehen',
        address: 'Straße Hausnummer ist erforderlich',
        zipInvalid: 'Ungültige Postleitzahl',
        emailInvalid: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
        domain: 'Domain ist erforderlich',
        domainInvalid: 'Bitte geben Sie eine gültige Domain ein',
      },
      tenants: 'Tenants',
    },
    profile: {
      changePassword: 'Passwort ändern',
      deletedSuccessfully: 'Profil erfolgreich gelöscht',
      returnToUsers: 'Rückkehr zu Benutzern',
      editProfile: 'Profil bearbeiten',
      deleteProfile: 'Profil löschen',
      deleteProfileText:
        'Wenn Sie Ihr Profil löschen, können Sie sich mit dem aktuellen Benutzer NICHT bei der Plattform anmelden. Alle Ihre personenbezogenen Daten werden gelöscht. Diese Aktion kann nicht rückgängig gemacht werden. Sind Sie sicher, dass Sie fortfahren möchten.',
      fields: {
        language: 'Sprache',
        name: 'Name',
        role: 'Rolle',
        email: 'Email',
        gender: 'Geschlecht',
      },
      validation: {
        name: 'Name ist erforderlich',
      },
    },
    users: {
      allUsers: 'Alle Benutzer',
      details: 'Einzelheiten',
      list: {
        contextMenu: {
          areYouSureAboutBlocking: 'Sind Sie sicher, dass Sie den Benutzer blockieren möchten',
          areYouSureAboutUnblocking: 'Sind Sie sicher, dass Sie den Benutzer entblocken möchten',
          unblock: 'Entblocken',
          block: 'Blockieren',
          blockUser: 'Benutzer blockieren',
          unblockUser: 'Benutzer entblocken',
          cancel: 'Abbrechen',
        },
      },
      fields: {
        id: 'ID',
        name: 'Name',
        email: 'Email',
        role: 'Role',
        status: 'Status',
        blocked: 'Blocked',
        createdAt: 'Created On',
        gender: 'Gender',
      },
      validation: {
        genderIsRequired: 'Gender is required',
      },
    },
    tenantNotifications: {
      otherTenantSelected:
        'Sie sind als {{ownTenantName}} Tenant angemeldet. Einige Funktionen bei Tenant {{tenantName}} sind nicht verfügbar. Loggen Sie sich bitte in ',
    },
    campaigns: {
      newCampaignPageHeader: 'Neue Kampagne',
      allCampaigns: 'Alle Kampagnen',
      locationType: {
        specificArea: 'Standort',
        radius: 'Umkreis',
      },
      list: {
        downloadReport: 'Download report',
        tableLabels: {
          id: 'ID',
          name: 'Kampagnenname',
          type: 'Typ',
          client: 'Kunde',
          startDate: 'Startdatum',
          endDate: 'Enddatum',
          specifyTime: 'Geben Sie die Start- und Endzeit an',
          status: 'Status',
          totalBudget: '',
          createdAt: 'Erstellt am',
        },
      },
      preview: {
        preview: 'Vorschau',
        previewIsNotAvailable: 'Die AMPHTML-Vorschau ist aktuell nicht verfügbar',
        selectDevice: 'Gerät für Vorschau auswählen',
        selectImageSize: 'Wählen Sie die Bildgröße für die Vorschau aus',
        desktop: 'Desktop',
        mobile: 'Mobil',
        addHeadline: 'Überschrift hinzufügen',
        addDescription: 'Beschreibung hinzufügen',
        addBusinessName: 'Firmennamen hinzufügen',
        selectAmphtml: 'Wählen Sie AMPHTML für die Vorschau aus',
        selectImages: 'Wählen Sie Bild für die Vorschau aus',
      },
      buttonNames: {
        createNewCampaign: 'Neue Kampagne Anlegen',
        next: 'nächste',
        prev: 'Vorher',
        prevOnLast: 'Zurück',
        completeAndView: 'Kampagnenzusammenfassung',
        backToList: 'Zurück zu allen Kampagnen',
        copy: 'Kopieren',
        edit: 'Bearbeiten',
        publish: 'Veröffentlichen',
        proceedPublish: 'Ich habs',
        delete: 'Löschen',
        save: 'Speichern',
        discardChanges: 'Zurück zur Ansicht',
        cancel: 'Kampagne abbrechen',
        saveAndPublish: 'Speichern & veröffentlichen',
        backToEditing: 'zurück zur Bearbeitung',
      },
      modals: {
        newClient: {
          createClientHeader: 'Neuen Kunden anlegen',
          noClientsFound: 'Kein Kunde gefunden',
          tryAnotherName: 'Bitte versuchen Sie eine andere Schreibweise, oder',
          createNewClient: 'Legen Sie einen neuen Kunden an',
          close: 'Schließen',
          save: 'Anlegen',
        },
        leave: {
          header: 'Sind Sie sicher, dass Sie gehen? Von Ihnen vorgenommene Änderungen werden nicht gespeichert',
        },
        cancel: {
          header: 'Kampagne ist fertig!',
          body: 'Ihre Kampagne wird validiert und zum geplanten Datum gestartet.',
          proceedButton: 'Ich habs',
        },
        saveAndPublish: {
          header: 'Kampagne ist fertig!',
          body: 'Ihre Kampagne wird validiert und zum geplanten Datum gestartet.',
          proceedButton: 'Ok',
        },
        discard: {
          header: 'Zurück zur Ansicht?',
          body: 'Alle Änderungen werden nicht gespeichert.',
          proceedButton: 'Verwerfen',
        },
        publish: {
          header: 'Kampagne ist fertig!',
          body: 'Ihre Kampagne wird validiert und zum geplanten Datum gestartet.',
          proceedButton: 'Ok',
        },
        delete: {
          body: {
            areYouSure: 'Bist Du sicher, dass Du die Kampagne',
            toCancel: 'wirklich abbrechen?',
            thisCannotBeReverted: 'Dies kann nicht rückgängig gemacht werden.',
          },
        },
      },
      fields: {
        days: 'Tage',
        hours: 'Std.',
        client: 'Kunde',
        campaign: 'Kampagne',
        status: 'Status',
        startDate: 'Startdatum',
        endDate: 'Enddatum',
        startEndDate: 'Start-Enddatum',
        startTime: 'Startzeit',
        endTime: 'Endzeit',
        dailyBudget: 'Tägliches Budget',
        totalBudget: 'Komplettes Budget',
        biddingStrategy: 'Gebotsstrategie',
        CurrentAI: 'Impressionen',
        budget: 'Budget',
        duration: 'Dauer',
        scheduleAndBudget: 'Laufzeit und Budget',
        gender: 'Geschlecht',
        name: 'Name',
        url: 'URL',
        headline: 'Überschrift',
        longHeadline: 'Überschrift',
        description: 'Beschreibung',
        businessName: 'Firmenname',
        linkedUrl: 'Ziel-URL (Wohin soll die Werbung verlinken?)',
      },
      validation: {
        cannotAddMoreLocations: 'Sie können nicht mehr als {{locations}} Standorte hinzufügen',
        cannotAddMoreImages: 'Cannot add more than {{maximumImages}} images',
        useShortDateFormat: 'Invalid date. Please use dd–MM–yyyy format',
        startDateCannotBeEarlierThanTomorrow: 'Das Startdatum kann nicht vor morgen liegen',
        endDateCannotBeEarlierThanTomorrow: 'Das Enddatum kann nicht vor morgen liegen',
        endDateCannotBeEarlierThanStartDate: 'Das Enddatum darf nicht vor dem Startdatum liegen',
        amphtmlShouldBeAdded: 'AMPHTML zip should be added',
        cannotAddMoreAmphtmlFiles: 'Es ist nicht erlaubt mehr als {{maximumFiles}} Zip Dateien hochzuladen',
        atLeastOneImageShouldBeSet:
          'Um die Kampagne einreichen zu können, muss erst mindestens ein Werbemittel hochgeladen werden',
        pointPositionShouldBeSet: 'Die Punktposition muss ebenfalls festgelegt werden',
        locationsListShouldBeSet: 'Liste der Standorte muss eingestellt werden',
        totalBudgetRange: 'Tagesbudget von 4 bis 4000',
        atLeastOneDeviceShouldBeChosen: 'Mindestens ein Gerätetyp sollte ausgewählt werden',
        campaignsNameIsTooLong: 'Kampagnenname ist zoo lang',
        campaignsNameIsRequired: 'Kampagnenname ist erforderlich',
        invalidCoordinates: 'Breiten- und Längengrad einrichten',
        protocol: 'URL sollte mit Protokoll beginnen: http:// oder https://',
        maximumImagesError: 'Bilder können nicht mehr als sein {{allowed}}',
        atLeastOneShouldBeSet: 'Mindestens ein {{resolution}} Bild eingestellt werden soll',
        locationTypeNotSelected: 'Standorttyp wurde nicht ausgewählt',
        locationsMaximumReached: 'Locations cannot be more than {{number}}',
        locationsCannotAddMore: 'Sie können nicht mehr als {{number}} Standorte hinzufügen',
        locationsWereNotProvided: 'Der Punktort oder eine Liste von Orten sollte bereitgestellt werden',
      },
      failedStatusDescription: {
        8000: 'Die Überprüfung der Kampagne ist fehlgeschlagen. Es ist ein unerwarteter Fehler aufgetreten. Bitte kontaktieren Sie Ihre Administratoren oder erstellen Sie eine neue Kampagne.',
        8101: 'Die Überprüfung der Kampagne ist fehlgeschlagen. Der ausgewählte Standort wird für das Targeting nicht unterstützt. Bitte überprüfen Sie die Standorte in der Zielgruppenansprache und senden Sie die Kampagne erneut.',
        8201: 'Die Überprüfung der Kampagne ist fehlgeschlagen. {Fehlermeldung}',
        8202: 'Die Überprüfung der Kampagne ist fehlgeschlagen. Die URL ist nicht gültig. Bitte überprüfen Sie die URL in den Werbematerialien und reichen Sie die Kampagne erneut ein.',
        8301: 'Die Überprüfung der Kampagne ist fehlgeschlagen. Die Mediendatei ist nicht gültig. Bitte überprüfen Sie die Medien in den Werbematerialien und reichen Sie die Kampagne erneut ein.',
        8302: 'Die Überprüfung der Kampagne ist fehlgeschlagen. Bei der Animation sind visuelle Effekte nicht zulässig.',
      },
      adServerErrorDescription: {
        'Location is invalid.': 'Der Ort ist ungültig.',
        'The image is not valid.': 'Das Bild ist nicht zulässig.',
        'The image could not be stored.': 'Das Bild konnte nicht gespeichert werden.',
        'There was a problem with the request.': 'Es gab ein Problem mit der Anfrage.',
        'The image is not of legal dimensions.': 'Das Bild hat nicht die zulässigen Abmessungen.',
        'Animated image are not permitted.': 'Animierte Bilder sind nicht zulässig.',
        'Animation is too long.': 'Die Animationen sind zu lang.',
        'There was an error on the server.': 'Es gab einen Fehler auf dem Server.',
        'Image cannot be in CMYK color format.': 'Das Bild kann nicht im CMYK-Farbformat vorliegen.',
        'Unacceptable visual effects.': 'Unzulässige visuelle Effekte.',
        'Incorrect image layout.': 'Fehlerhaftes Bildlayout.',
        'There was a problem reading the image file.': 'Es gab ein Problem beim Lesen der Bilddatei.',
        'There was an error storing the image.': 'Es ist ein Fehler beim Speichern des Bildes aufgetreten.',
        'The aspect ratio of the image is not allowed.': 'Das Bildseitenverhältnis ist nicht zulässig.',
        'Image file is too large.': 'Die Bilddatei ist zu gross.',
        'Image data is too large.': 'Die Bilddaten sind zu gross.',
        'Error while processing the image.': 'Fehler bei der Verarbeitung des Bildes.',
        'Image is too small.': 'Das Bild ist zu klein.',
        'Input was invalid.': 'Die Eingabe war ungültig.',
        'Image format is not allowed.': 'Das Bildformat ist nicht zulässig.',
        /////////
        'Google Ads API encountered unexpected internal error.':
          'Google Ads API ist auf einen unerwarteten internen Fehler gestoßen.',
        "The intended error code doesn't exist in specified API version. It will be released in a future API version.":
          'Der vorgesehene Fehlercode ist in der angegebenen API-Version nicht vorhanden. Er wird in einer zukünftigen API-Version veröffentlicht werden.',
        'Google Ads API encountered an unexpected transient error. The user should retry their request in these cases.':
          'Bei der Google Ads-API ist ein unerwarteter vorübergehender Fehler aufgetreten. Der Nutzer sollte in diesen Fällen seine Anfrage wiederholen.',
        'The request took longer than a deadline.': 'Der Antrag dauerte länger als die Frist.',
        /////////
        'Cannot create a standard icon type.': 'Es kann kein Standardsymboltyp erstellt werden.',
        'Image contains both a media file ID and data.': 'Image enthält sowohl eine Mediendatei-ID als auch Daten.',
        'A media file with given type and reference ID already exists.':
          'Eine Mediendatei mit dem angegebenen Typ und der Referenz-ID existiert bereits.',
        'A required field was not specified or is an empty string.':
          'Ein erforderliches Feld wurde nicht angegeben oder ist eine leere Zeichenfolge.',
        'The media file ID is invalid.': 'Die Mediendatei-ID ist ungültig.',
        'The media subtype is invalid.': 'Der Medien-Subtyp ist ungültig.',
        'The media file type is invalid.': 'Der Mediendateityp ist ungültig.',
        'The mimetype is invalid.': 'Der Mimetyp ist ungültig.',
        'The media reference ID is invalid.': 'Die Medienreferenz-ID ist ungültig.',
        'Media file has failed transcoding': 'Die Transkodierung der Mediendatei ist fehlgeschlagen',
        'Media file has not been transcoded.': 'Die Mediendatei wurde nicht transkodiert.',
        "The media type does not match the actual media file's type.":
          'Der Medientyp stimmt nicht mit dem Typ der tatsächlichen Mediendatei überein.',
        'The string has too many characters.': 'Die Zeichenkette hat zu viele Zeichen.',
        'The specified type is not supported.': 'Der angegebene Typ wird nicht unterstützt.',
        ///////////
        'The uploaded file is too big.': 'Die hochgeladene Datei ist zu gross.',
        'Image data is unparseable.': 'Bilddaten sind unzerlegbar.',
        'Animated images are not allowed.': 'Animierte Bilder sind nicht erlaubt.',
        'The image or media bundle format is not allowed.': 'Das Bild- oder Medienbündelformat ist nicht erlaubt.',
        'Cannot reference URL external to the media bundle.':
          'Es kann nicht auf eine URL außerhalb des Medienbündels verwiesen werden.',
        'HTML5 ad is trying to reference an asset not in .ZIP file.':
          'Die HTML5-Anzeige versucht, auf ein Asset zu verweisen, das nicht in der ZIP-Datei enthalten ist.',
        'The media bundle contains no primary entry.': 'Das Medienbündel enthält keinen Primäreintrag.',
        'Animation has disallowed visual effects.': 'Bei der Animation sind visuelle Effekte nicht erlaubt.',
        'Animation longer than the allowed 30 second limit.':
          'Animation, die länger als die erlaubten 30 Sekunden ist.',
        'The aspect ratio of the image does not match the expected aspect ratios provided in the asset spec.':
          'Das Seitenverhältnis des Bildes stimmt nicht mit den in der Asset-Spezifikation angegebenen erwarteten Seitenverhältnissen überein.',
        'Audio files are not allowed in bundle.': 'Audiodateien sind im Bündel nicht zulässig.',
        'CMYK jpegs are not supported.': 'CMYK-JPEGs werden nicht unterstützt.',
        'Flash movies are not allowed.': 'Flash-Filme sind nicht zulässig.',
        'The frame rate of the video is higher than the allowed 5fps.':
          'Die Bildrate des Videos ist höher als die zulässigen 5 Bilder pro Sekunde.',
        'ZIP file from Google Web Designer is not published.':
          'Die ZIP-Datei von Google Web Designer wird nicht veröffentlicht.',
        'Image constraints are violated, but more details (like DIMENSIONS_NOT_ALLOWED or ASPECT_RATIO_NOT_ALLOWED) can not be provided. This happens when asset spec contains more than one constraint and criteria of different constraints are violated.':
          'Bildbeschränkungen werden verletzt, aber weitere Details (wie DIMENSIONS_NOT_ALLOWED oder ASPECT_RATIO_NOT_ALLOWED) können nicht angegeben werden. Dies geschieht, wenn die Asset-Spezifikation mehr als eine Beschränkung enthält und Kriterien verschiedener Beschränkungen verletzt werden.',
        'Media bundle data is unrecognizable.': 'Die Daten des Medienpakets sind unkenntlich.',
        'There was a problem with one or more of the media bundle entries.':
          'Es gab ein Problem mit einem oder mehreren Einträgen des Medienpakets.',
        'The asset has an invalid mime type.': 'Das Asset hat einen ungültigen Mime-Typ.',
        'The media bundle contains an invalid asset path.': 'Das Medienbündel enthält einen ungültigen Asset-Pfad.',
        'Image has layout problem.': 'Das Bild hat ein Layout-Problem.',
        'An asset had a URL reference that is malformed per RFC 1738 convention.':
          'Ein Asset hatte einen URL-Verweis, der gemäß der RFC 1738-Konvention fehlerhaft ist.',
        'The uploaded media bundle format is not allowed.':
          'Das Format des hochgeladenen Medienpakets ist nicht zulässig.',
        'The media bundle is not compatible with the asset spec product type. (For example, Gmail, dynamic remarketing, etc.)':
          'Das Medianpaket ist nicht mit dem Produkttyp der Asset-Spezifikation kompatibel. (Zum Beispiel Google Mail, dynamisches Remarketing usw.)',
        'A bundle being uploaded that is incompatible with multiple assets for different reasons.':
          'Ein Bundle wird hochgeladen, das aus unterschiedlichen Gründen mit mehreren Assets nicht kompatibel ist.',
        'The media bundle contains too many files.': 'Das Medienpaket enthält zu viele Dateien.',
        'Google Web Designer not created for "Google Ads" environment.':
          'Google Web Designer wurde nicht für die "Google Ads"-Umgebung entwickelt.',
        'Unsupported HTML5 feature in HTML5 asset.': 'Nicht unterstützte HTML5-Funktion im HTML5-Asset.',
        'URL in HTML5 entry is not SSL compliant.': 'Die URL im HTML5-Eintrag ist nicht SSL-konform.',
        'Video file name is longer than the 50 allowed characters.':
          'Der Name der Videodatei ist länger als die erlaubten 50 Zeichen.',
        'Multiple videos with same name in a bundle.': 'Mehrere Videos mit demselben Namen in einem Bündel.',
        'Videos are not allowed in media bundle.': 'Videos sind in Medienpaketen nicht zulässig.',
        'This type of media cannot be uploaded through the Google Ads API.':
          'Diese Art von Medien kann nicht über die Google Ads-API hochgeladen werden.',
        'The dimensions of the image are not allowed.': 'Die Abmessungen des Bildes sind nicht zulässig.',
        'The resource has been disapproved since the policy summary includes policy topics of type PROHIBITED.':
          'Die Ressource wurde abgelehnt, da die Zusammenfassung der Richtlinien Themen des Typs PROHIBITED enthält.',
        'The given policy topic does not exist.': 'Das angegebene Richtlinienthema existiert nicht.',
        '# PolicyValidationParameterError': '# PolicyValidationParameterError',
        'Ignorable policy topics are not supported for the ad type.':
          'Ignorierbare Richtlinienthemen werden für diesen Anzeigentyp nicht unterstützt.',
        'Exempt policy violation keys are not supported for the ad type.':
          'Für diesen Anzeigentyp werden keine Schlüssel für Richtlinienverstöße unterstützt.',
        'Cannot set ignorable policy topics and exempt policy violation keys in the same policy violation parameter.':
          'Es können keine ignorierbaren Richtlinienthemen und Schlüssel für ausgenommene Richtlinienverletzungen im selben Richtlinienverletzungsparameter festgelegt werden.',
        'A policy was violated. See PolicyViolationDetails for more detail.':
          'Es wurde gegen eine Richtlinie verstoßen. Siehe PolicyViolationDetails für weitere Details.',
        'The input string value contains disallowed characters.':
          'Der Wert der Eingabezeichenfolge enthält unzulässige Zeichen.',
        'The input string value is invalid for the associated field.':
          'Der Wert der Eingabezeichenfolge ist für das zugehörige Feld ungültig.',
        'The specified field should have a least one non-whitespace character in it.':
          'Das angegebene Feld sollte mindestens ein Zeichen enthalten, das nicht aus einem Leerzeichen besteht.',
        'Too short.': 'Zu kurz.',
        'Too long.': 'Zu lang.',
        'The tracking url template is invalid.': 'Die Vorlage für die Tracking-URL ist ungültig.',
        'The tracking url template contains invalid tag.': 'Die Tracking-URL-Vorlage enthält ein ungültiges Tag.',
        'The tracking url template must contain at least one tag (for example, {lpurl}), This applies only to tracking url template associated with website ads or product ads.':
          'Die Vorlage für die Tracking-URL muss mindestens ein Tag (z. B. {lpurl}) enthalten. Dies gilt nur für Tracking-Url-Vorlagen, die mit Website-Anzeigen oder Produktanzeigen verbunden sind.',
        'The tracking url template must start with a valid protocol (or lpurl tag).':
          'Die Vorlage für die Tracking-URL muss mit einem gültigen Protokoll (oder lpurl-Tag) beginnen.',
        'The tracking url template starts with an invalid protocol.':
          'Die Vorlage für die Tracking-URL beginnt mit einem ungültigen Protokoll.',
        'The tracking url template contains illegal characters.':
          'Die Vorlage für die Tracking-URL enthält unzulässige Zeichen.',
        'The tracking url template must contain a host name (or lpurl tag).':
          'Die Vorlage für die Tracking-URL muss einen Hostnamen (oder ein lpurl-Tag) enthalten.',
        'The tracking url template has an invalid or missing top level domain extension.':
          'Die Tracking-URL-Vorlage hat eine ungültige oder fehlende Top-Level-Domain-Erweiterung.',
        'The tracking url template contains nested occurrences of the same conditional tag (for example, {ifmobile:{ifmobile:x}}).':
          'Die Vorlage für die Tracking-URL enthält verschachtelte Vorkommen desselben bedingten Tags (z. B. {ifmobile:{ifmobile:x}}).',
        'The final url is invalid.': 'Der endgültige URL ist ungültig.',
        'The final url contains invalid tag.': 'Die endgültige URL enthält ungültige Tags.',
        'The final url contains nested occurrences of the same conditional tag (for example, {ifmobile:{ifmobile:x}}).':
          'Die endgültige URL enthält verschachtelte Vorkommen desselben bedingten Tags (z. B. {ifmobile:{ifmobile:x}}).',
        'The final url must start with a valid protocol.':
          'Die endgültige URL muss mit einem gültigen Protokoll beginnen.',
        'The final url starts with an invalid protocol.': 'Die endgültige URL beginnt mit einem ungültigen Protokoll.',
        'The final url contains illegal characters.': 'Die endgültige URL enthält illegale Zeichen.',
        'The final url must contain a host name.': 'Die endgültige URL muss einen Hostnamen enthalten.',
        'The final mobile url is invalid.': 'Die endgültige mobile URL ist ungültig.',
        'The final mobile url contains invalid tag.': 'Die endgültige mobile URL enthält ein ungültiges Tag.',
        'The final mobile url contains nested occurrences of the same conditional tag (for example, {ifmobile:{ifmobile:x}}).':
          'Die endgültige mobile URL enthält verschachtelte Vorkommen desselben bedingten Tags (z. B. {ifmobile:{ifmobile:x}}).',
        'The final mobile url must start with a valid protocol.':
          'Die endgültige mobile URL muss mit einem gültigen Protokoll beginnen.',
        'The final mobile url starts with an invalid protocol.':
          'Die endgültige mobile URL beginnt mit einem ungültigen Protokoll.',
        'The final mobile url contains illegal characters.': 'Die endgültige mobile URL enthält unzulässige Zeichen.',
        'The final mobile url must contain a host name.': 'Die endgültige mobile URL muss einen Hostnamen enthalten.',
        'The final app url is invalid.': 'Die endgültige App-URL ist ungültig.',
        'The final app url contains invalid tag.': 'Die endgültige App-URL enthält ein ungültiges Tag.',
        'The final app url contains nested occurrences of the same conditional tag (for example, {ifmobile:{ifmobile:x}}).':
          'Die endgültige App-URL enthält verschachtelte Vorkommen des gleichen bedingten Tags (z. B. {ifmobile:{ifmobile:x}}).',
        'More than one app url found for the same OS type.':
          'Es wurde mehr als eine App-URL für denselben Betriebssystemtyp gefunden.',
        'The OS type given for an app url is not valid.':
          'Der für eine App-URL angegebene Betriebssystemtyp ist nicht gültig.',
        'The protocol given for an app url is not valid. (For example, "android-app")':
          'Das für eine App-Url angegebene Protokoll ist nicht gültig. (Zum Beispiel: "android-app://")',
        'The package id (app id) given for an app url is not valid.':
          'Die für eine App-URL angegebene Paket-ID (App-ID) ist nicht gültig.',
        'The number of url custom parameters for an resource exceeds the maximum limit allowed.':
          'Die Anzahl der benutzerdefinierten URL-Parameter für eine Ressource überschreitet die zulässige Höchstgrenze.',
        'An invalid character appears in the parameter key.': 'Im Parameterschlüssel erscheint ein ungültiges Zeichen.',
        'An invalid character appears in the parameter value.': 'Ein ungültiges Zeichen erscheint im Parameterwert.',
        'The url custom parameter value fails url tag validation.':
          'Der Wert des benutzerdefinierten Parameters url schlägt bei der Validierung des URL-Tags fehl.',
        'The custom parameter contains nested occurrences of the same conditional tag (for example, {ifmobile:{ifmobile:x}}).':
          'Der benutzerdefinierte Parameter enthält verschachtelte Vorkommen desselben bedingten Tags (z. B. {ifmobile:{ifmobile:x}}).',
        'The protocol (http or https) is missing.': 'Das Protokoll (http:// oder https://) fehlt.',
        'Unsupported protocol in URL. Only http and https are supported.':
          'Nicht unterstütztes Protokoll in der URL. Nur http und https werden unterstützt.',
        'The url is invalid.': 'Die URL ist ungültig.',
        'Destination Url is deprecated.': 'Die Ziel-URL ist veraltet.',
        'The url contains invalid tag.': 'Die URL enthält einen ungültigen Tag.',
        'The url must contain at least one tag (for example, {lpurl}).':
          'Die URL muss mindestens ein Tag enthalten (z. B. {lpurl}).',
        'Duplicate url id.': 'Doppelte URL-ID.',
        'Invalid url id.': 'Ungültige URL-ID.',
        "The final url suffix cannot begin with '?' or '&' characters and must be a valid query string.":
          "Das endgültige Suffix der URL darf nicht mit den Zeichen '?' oder '&' beginnen und muss eine gültige Abfragezeichenfolge sein.",
        'The final url suffix cannot contain {lpurl} related or {ignore} tags.':
          'Das Suffix der Endung URL darf keine {lpurl}-verwandten oder {ignore}-Tags enthalten.',
        'The top level domain is invalid, for example, not a public top level domain listed in publicsuffix.org.':
          'Die Top-Level-Domain ist ungültig, zum Beispiel keine öffentliche Top-Level-Domain, die in publicsuffix.org aufgeführt ist.',
        'Malformed top level domain in URL.': 'Fehlerhafte Top-Level-Domain in der URL.',
        'Malformed URL.': 'Fehlerhafte URL.',
        'No host found in URL.': 'In der URL wurde kein Host gefunden.',
        'Custom parameter value cannot be null.': 'Der Wert des benutzerdefinierten Parameters kann nicht null sein.',
        'Track parameter is not supported.': 'Der Track-Parameter wird nicht unterstützt.',
      },
      status: {
        draft: 'Entwurf',
        ready: 'Bereit',
        active: 'Aktiv',
        // paused: 'Pausiert',
        canceled: 'Abgebrochen',
        failed: 'Fehlerhaft',
        completed: 'Abgeschlossen',
        inReview: 'In Prüfung',
      },
      clearAll: 'Alles löschen',
      bannerAdMedia: 'Werbemittel',
      locationHeading: 'Geo-Targeting',
      campaignName: 'Name Kampagne',
      campaignId: 'ID Kampagne',
      adTab: 'Werbemittel',
      targetingTag: 'Targeting',
      createCampaign: 'Neue Kampagne anlegen',
      advertiseInZones: 'In spezifischen PLZs, Städten oder Regionen werben',
      campaignsAttributeTab: 'KAMPAGNENATTRIBUTE',
      amphtmlDescription:
        'Sie können bis zu 10 ZIP-Dateien hochladen. Eine ZIP-Datei muss ein einzelnes HTML-Dokument und bis zu 39 Medien enthalten.',
      logo: 'Logo',
      generateReport: 'Bericht erstellen',
      generateReportCaption: 'Bitte warten, Bericht wird erstellt',
      generalAttributes: 'Allgemeine Attribute',
      setCampaignAttributes: 'Kampagnenattribute festlegen',
      optimization: 'Optimierung',
      targetingSettings: 'Ausrichtungseinstellungen',
      adSettings: 'Anzeigeneinstellungen',
      googleAdsServerOptimization: 'Google Ads Server-Optimierung',
      biddingStrategy: 'Gebotsstrategie',
      adType: 'Kampagnentyp',
      nearAddress: 'Im Umkreis einer Adresse werben',
      specificAddress: 'In spezifischen PLZs, Städten oder Regionen werben',
      targetingHeading: 'Targeting',
      address: 'PLZ, Stadt und/oder Region',
      devices: 'Endgeräte',
      desktop: 'Desktop',
      mobile: 'Mobil (Smartphones und Tablet)',
      linkedUrl: 'Ziel-URL (Wohin soll die Werbung verlinken?)',
      adMediaDesc:
        'Bitte mindestens ein Werbemittel (Medium Rectangle, 300x250 Pixel) hochladen. Gültige Dateiformate: png, jpg, gif.',
      imageSize: 'Medium Rectangle',
      uploadImage: 'Werbemittel hochladen',
      removeImage: 'Werbemittel entfernen',
      searchPlaces: 'Ort, PLZ oder Region ...',
      adSettingsDescription: 'Einstellungen Werbemittel',
      allStatuses: 'Filtern nach Status',
      types: {
        banner: 'Display',
        native: 'Native',
      },
      typesDescription: {
        native1:
          'Das Werbemittel wird automatisch an das jeweilige Design der Webseiten angepasst, auf denen es ausgespielt wird.',
        native2:
          'Die Werbung wird auf den ersten Blick als Content wahrgenommen, was die Aufmerksamkeit des Users deutlich erhöht.',
        native3: 'Hervorragend geeignet sowohl für Smartphones als auch für Desktop.',
        native4: 'Targetingoptionen: Ort und Geräte.',
        banner1:
          'Als Werbemittel wird ein Medium Rectangle mit 300x250 Pixeln genutzt, welches auf allen Webseiten gleich ausgespielt wird.',
        banner2:
          'Diese Bannergröße ist optimal für Smartphones geeignet, kann aber auch gut auf Desktops eingesetzt werden.',
        banner3: 'Targetingoptionen: Alter, Geschlecht, Ort und Geräte.',
      },
      adMedia: 'Werbemittel – Display-Anzeige',
      adMediaDescription:
        'Je mehr Bilder bereitgestellt werden, desto mehr Variationen Ihrer Anzeige können angezeigt werden.',
      adMediaNativeDescriptions:
        'Mit dieser Funktion werden Anzeigen in nativen Formaten generiert, um Ihre Reichweite auf mehr Publisher auszudehnen, darunter YouTube und Gmail. Das Hinzufügen nativer Formate kann auch die Anzeigenleistung verbessern.',
      adText: 'Anzeigentext',
      addAdAssets: 'Werbemittel hinzufügen',
      next: 'nächste',
      prev: 'Vorher',
      prevOnLast: 'Zurück',
      summary: 'Kampagnenzusammenfassung',
      marketingImages: 'Marketingbilder',
      headline: 'Überschrift',
      description: 'Beschreibung',
      businessName: 'Firmenname',
      optimizationBiddingValidationMessage: 'Die Gebotsstrategie ist ein Pflichtfeld',
      ageRangeValidationMessage: 'Sie müssen mindestens eine Altersgruppe auswählen',
      imageResolutionError: 'Bild entspricht nicht den Anforderungen. Die Auflösung sollte eine der folgenden sein', // 'Image does not match the requirements. Resolution should be one of the following',
      adTypeValidationMessage: `Sollte einer der folgenden sein: ${_.capitalize(TCampaignType.banner)}, ${_.capitalize(
        TCampaignType.native.toUpperCase()
      )}`,
      incorrectUrlValidationMessage: 'Bitte geben Sie eine gültige URL ein',
      statusDescription: {
        draft:
          'Der Kampagnendatensatz wird erstellt, aber erst gestartet, wenn Sie ihn mit ausgefüllten Pflichtfeldern übermitteln.',
        ready:
          'Die Kampagne wird erstellt und startet automatisch zum geplanten Datum.\n' +
          '\n' +
          'Sie können die Kampagne immer noch ändern und erneut einreichen.',
        active: 'Kampagne läuft derzeit.\n' + '\n' + 'Sie können die Kampagne immer noch ändern und erneut einreichen.',
        paused: 'Die Kampagne wurde pausiert. Sie können es vor dem Enddatum fortsetzen',
        canceled: 'Die Kampagne wurde manuell abgebrochen.',
        completed: 'Kampagne wird gemäß Enddatum abgeschlossen.',
        inReview:
          'Die Kampagne für einen der Kanäle wird derzeit überprüft. Es läuft derzeit nicht und wird wieder aufgenommen, sobald die Überprüfung abgeschlossen ist. Es sind keine Maßnahmen erforderlich.',
        failed:
          'Die Kampagne hat die Überprüfung nicht bestanden. Stellen Sie sicher, dass alle Attribute und Dateien den Richtlinien entsprechen, und reichen Sie die Kampagne erneut ein.',
      },
      performance: {
        name: 'Kampagnenkennzahlen',
        activeUpdated:
          'Die Daten zur Kampagnenkennzahlen werden täglich um 00:01 Uhr {{gmt}} aktualisiert. Zuletzt aktualisiert am {{date}}.',
        readyUpdated:
          'Die Daten zur Kampagnenkennzahlen werden täglich um 00:01 {{gmt}} nach Kampagnenstart aktualisiert.',
        completedUpdated: 'Zuletzt aktualisiert am {{date}}.',
        popup:
          'Um die Kampagnenergebnisse zu verbessern, sollten Sie folgende Maßnahmen ergreifen: Anpassung der Werbemotive, Verfeinerung der Geo- und Zielgruppen-Parameter.',
        currentImpressions: 'Aktuelle Impressionen',
        clicks: 'Klicks',
        clickRate: 'Klickrate',
        downloadReport: 'Bericht herunterladen',
      },
      note: {
        note: 'Notiz',
        save: 'speichern',
        cancel: 'abbrechen',
        editNote: 'Notiz bearbeiten',
        addNote: 'Notiz hinzufügen',
        question: 'Bist du sicher ?',
      },
      optimizationBidding: {
        bidStrategy: '\n' + 'Wählen Sie eine Gebotsstrategie',
        bidValue: 'Optimierung strategie Koeffizient',
        cpc: 'Auto-optimierter CPC',
        cpm: 'Sichtbarer CPM',
        maxClicks: 'Klicks maximieren',
        conversion: 'Conversions maximieren',
        labelMaxCpc: 'Maximales CPC-Gebot',
        labelMaxCpm: 'Maximaler CPM (Sichtbare)',
        labelTargetCpa: 'Ziel-CPA',
      },
    },
    reportMail: {
      body: 'Problem mit der Kampagne {{campaignName}} {{campaignLink}} {{tenantName}}%0ADetails:',
      tenantInfo: 'für den Tenant {{tenantName}}',
      subject: 'Problem mit der Kampagne {{campaignName}} {{tenantName}}',
    },
    help: {
      title: 'Häufig Gestellte Fragen',
      accordions: {
        1: {
          topic: 'Wie lege ich einen neuen Kunden an?',
          description:
            '<ol><li>Navigieren Sie im Hauptmenü zu "Kunden".</li><li>Klicken Sie auf <strong>Neuen kunden anlegen</strong> in der rechten oberen Ecke.<ul><li> Das Feld "Firma" ist ein Pflichtfeld und muss ausgefüllt werden. Alle weiteren Felder sind optional und dienen zur weiteren Zuordnung.</li></ul></li></ol>',
        },
        2: {
          topic: 'Wie lege ich eine Kampagne an?',
          description:
            '<ol><li>Navigieren Sie zur  "Liste der Kampagnen" im Hauptmenü.</li><li>Klicken Sie auf die Schaltfläche <strong>Neue Kampagne erstellen</strong> in der rechten oberen Ecke.<ul><li>Auf der Seite für die Erstellung einer neuen Kampagne legen Sie die ersten Attribute der Kampagne fest.</li><li>Ordnen Sie bitte einen zuvor angelegten Kunden der jeweiligen Kampagne hinzu. Sie können alle Kunden in einer separaten Liste im Hauptmenü anlegen und verwalten.</li></ul></li><li>Dann klicken Sie auf die Schaltfläche <strong>Speichern</strong>, um zu den weiteren Schritten "Zielgruppe und Umkreis" und "Werbemittel" zu gelangen.<ul><li><strong>Notiz:</strong> Der Start der Kampagne kann nur ein in der Zukunft liegendes Datum sein.</li></ul></li><li>Sie können eine Kampagne als Entwurf speichern oder die Kampagne veröffentlichen, wenn Sie sie für einsatzbereit halten. Weitere Einzelheiten zu den jeweiligen <u>Kampagnenstatus finden</u> Sie im nächsten Abschnitt.</li></ol>',
        },
        3: {
          topic: 'Welche Formate und Textlängen werden für die Werbemittel genutzt?',
          description:
            '<p>Für die <strong>Display Ad Kampagnen:</strong></p><ul><li>sind die Formate JPG, PNG, GIF und AMPHTML jeweils 300x250px und in der maximalen Größe von 150kb zulässig.</li></ul><p>Für die <strong>Native Ad Kampagnen:</strong></p><ul><li>sind die Formate JPG, PNG und GIF jeweils 300x300px und 600x314px in der maximalen Größe von 150kb zulässig. </li><li>Die Überschrift der Native Ad Kampagnen darf maximal 70 Zeichen, die Beschreibung 350 Zeichen und der Firmenname 25 Zeichen enthalten. </li></ul></li></ul></li></ul></li></ul><p>GIFs können animiert sein, aber die Länge muss unter 30 Sekunden liegen; sie können in einer Schleife laufen, müssen aber nach 30 Sekunden beenden; sie müssen langsamer als 5 FPS sein).</p><p>Die Ziel-URL muss gültig und erreichbar sein und darf keine Fehlercodes zurückgeben oder eine Autorisierung erfordern. Eine Umleitung (einschließlich einer Umleitung von der öffentlichen Domäne zur öffentlichen Subdomäne) ist nicht zulässig. </p>',
        },
        4: {
          topic: 'Welchen Status können Kampagnen haben und wie kann man diesen verwalten?',
          description:
            '<ul><li>Jede übermittelte Kampagne trägt zuerst den Status "Bereit" und wird zum Datum des eingetragenen Kampagnen-Starts "Aktiv", wenn alle Werbemittel den Anforderungen entsprechen.</li><li>Die Kampagne wird automatisch am Datum des Kampagnen-Endes "Abgeschlossen" oder wenn der Benutzer die Kampagne manuell beendet.</li><li>Sie können auch jede aktive Kampagne abbrechen. Dies bedeutet, dass die Kampagne "Abgebrochen" wird. Wir stellen die Schaltung von Anzeigen in dieser Kampagne ein und die Kampagne wird geschlossen, ohne dass Sie sie erneut öffnen können.</li><li>Der Status "Fehlerhaft" ist selten und wird für eine Kampagne vergeben, wenn die darin enthaltenen Anzeigen gegen ethische Regeln verstoßen (Nacktheit, Rassismus usw.).<br><strong>Notiz:</strong> Der Benutzer kann die Kampagnendetails, das Targeting oder die Anzeigen bearbeiten, wenn die Kampagne nicht "Aktiv", "Abgebrochen" oder "Abgeschlossen" ist.</li><li>In einigen seltenen Fällen kann es vorkommen, dass der oder die Anzeigenserver, auf welche/n die Kampagne weitergeleitet wird, eine zusätzliche Validierung der Ads verlangen, was leider bis zu mehreren Tagen dauern kann. Dies ist jedoch äußerst selten der Fall. Während dieser Validierungen erhalten die Kampagnen automatisch den Status "In Review" und werden nach Abschluss der Validierung automatisch auf "Aktiv" zurückgesetzt. Solange die Kampagne validiert wird, kann sie nicht an den/die betreffenden Anzeigenserver weitergeleitet werden.</li></ul>',
        },
        5: {
          topic: 'Wie manage ich Kampagnen eines einzelnen Kunden?',
          description:
            '<ul><li>Um alle neuen und bestehenden Kampagnen, die zu einem einzelnen Werbetreibenden gehören, zu gruppieren, sollte jede Kampagne einem bestimmten Kundendatensatz zugeordnet sein (dies wird in der Kampagnenkarte beim Erstellen oder Bearbeiten der Kampagne festgelegt).</li><li>Sie können eine Liste von Kunden erstellen und verwalten, indem Sie im Hauptmenü zum Menüpunkt <strong>"Kunden"</strong> navigieren.</li></ul>',
        },
        6: {
          topic: 'Wie manage ich die Benutzer?',
          description:
            '<ul><li>Als Administrator können Sie anderen Personen dieselbe Rolle zuweisen oder andere Benutzer entfernen/löschen.</li><li>Sie können keine Benutzer erstellen, jeder sollte sich auf der Login-Seite registrieren und erst nachdem der Benutzer existiert, können Sie ihm eine Rolle zuweisen, damit dieser Benutzer Zugang zum System erhält.</li><li>Gesperrte Benutzer haben keine Möglichkeit sich einzuloggen und erhalten eine entsprechende Nachricht darüber.</li><li>Benutzer mit der Rolle "Administrator", können die Ausspielung jedes einzelnen Kanals aktivieren oder deaktivieren.</li></ul>',
        },
        7: {
          topic: 'Wie kann ich die Daten meines persönlichen Profils ändern?',
          description:
            '<ul><li>Sie können Ihre Daten (E-Mail, Name usw.) im persönlichen Menü in der oberen rechten Ecke jeder Seite ändern. </li><li>Sie können auch Ihr Passwort ändern und Ihr Profil vollständig aus dem System löschen (beachten Sie, dass Sie in diesem Fall Ihren Zugang zum System verlieren).</li></ul>',
        },
        8: {
          topic: 'Wie sehe ich die Reportings der Kampagnen?',
          description:
            '<p>Navigieren Sie einfach zu einer beliebigen Kampagne und wählen Sie aus dem Kampagnenmenü <strong>"Report herunterladen"</strong>. Die Datei wird als PDF heruntergeladen und enthält Diagramme zu Klicks, Impressionen und der Klickrate im Zeitverlauf.</p>',
        },
        9: {
          topic: 'Kann ich das Erscheinungsbild meines Bereichs anpassen?',
          description:
            '<p>Ja, Sie können die Optik des gesamten Bereichs für alle Ihre Benutzer anpassen. Kontaktieren Sie einfach Ihren Ansprechpartner bei AdOnce um diese Änderung zu beantragen.</p>',
        },
      },
    },
    subscriptions: {
      subscription: 'Abonnement',
      create: {
        specifyIfCurrentCampaignInPartOfSubs:
          'Geben Sie an, ob die aktuelle Kampagne mit einem Abonnement verknüpft ist',
        subscriptionCampaign: 'Abonnement-Kampagne',
        previousCampaign: 'Vergangene Kampagne',
        newSubscription: 'Neues Abonnement',
        selectAPreviousCampaignFromList:
          'Wählen Sie eine vergangene Kampagne aus bestehenden Abonnements oder starten Sie ein Neues Abonnement',
        campaignDurationRecomentation:
          'Für optimale Ergebnisse wird empfohlen, dass die Kampagnendauer mindestens einen Monat betragen sollte.',
      },
      view: {
        campaignIsPartOfSubscription: 'Diese Kampagne ist mit einem Abonnement verbunden',
        previousCampaign: 'Frühere Kampagne',
        none: 'Keine',
        campaignIsNotAPartOfSubscription: 'Diese Kampagne ist keinem Abonnement zugeordnet',
      },
      modals: {
        delete: {
          header: 'Kampagne löschen?',
          campaignDeleteWarning1: 'Sind Sie sicher, dass Sie die Kampagne',
          campaignDeleteWarning2: 'löschen möchten ',
          campaignDeleteWarning3: 'Dieser Vorgang kann nicht rückgängig gemacht werden.',
          nextCampaign: 'Die nächste Kampagne ',
          willBeLinkedTo: 'im Abonnement wird mit der vorherigen Kampagne',
          to: 'verknüpft.',
        },
      },
      buttons: {
        cancel: 'Zurück',
        delete: 'Kampagne löschen',
      },
      tables: {
        inSubscription: 'Abonniert',
        notInSubscription: 'Nicht abonniert',
        all: 'Alle',
        hintText: 'Diese Kampagne ist mit einem Abonnement verbunden',
        sub: 'Abo',
      },
    },
  },
}
