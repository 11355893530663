import _ from 'lodash'

import { TCampaignType } from '../core/type'

export const en = {
  translation: {
    errorBoundary: {
      siteWasNotFound: 'The site was not found',
      pageDoesNotExist: 'Unfortunately, the page you are looking for does not exist or the link is out of date.',
    },
    header: {
      menu: {
        editProfile: 'Edit profile',
        changePassword: 'Change Password',
        logout: 'Logout',
      },
      navigation: {
        campaign: 'Campaigns',
        client: 'Clients',
        user: 'Users',
        tenants: 'Tenants',
      },
    },
    footer: {
      termsAndConditions: 'Terms and Conditions',
      privacyPolicy: 'Data protection',
      imprint: 'Imprint',
    },
    notFound: {
      404: 404,
      siteWasNotFound: 'The site was not found',
      pageDoesNotExist: 'Unfortunately, the page you are looking for does not exist or the link is out of date.',
    },
    errorHandling: {
      400: {
        header: 'Bad request',
        body: 'The server cannot or will not process the request due to something that is perceived to be a client error (e.g., malformed request syntax, invalid request message framing, or deceptive request routing).',
      },
      401: {
        header: 'Unauthorized',
        body: 'The request has not been applied because it lacks valid authentication credentials for the target resource.',
      },
      402: {
        header: 'PAYMENT REQUIRED',
        body: 'Reserved for future use.',
      },
      403: {
        header: 'Forbidden',
        body: 'The server understood the request but refuses to authorize it.',
      },
      404: {
        header: 'Not found',
        body: 'The origin server did not find a current representation for the target resource or is not willing to disclose that one exists.',
      },
      405: {
        header: 'Method not allowed',
        body: 'The method received in the request-line is known by the origin server but not supported by the target resource.',
      },
      500: {
        header: 'Internal server error',
        body: 'The server encountered an unexpected condition that prevented it from fulfilling the request.',
      },
      501: {
        header: 'Not implemented',
        body: 'The server does not support the functionality required to fulfill the request.',
      },
      502: {
        header: 'Bad gateway',
        body: 'The server, while acting as a gateway or proxy, received an invalid response from an inbound server it accessed while attempting to fulfill the request.',
      },
      503: {
        header: 'Service unavailable',
        body: 'The server is currently unable to handle the request due to a temporary overload or scheduled maintenance, which will likely be alleviated after some delay.',
      },
      504: {
        header: 'Gateway timeout',
        body: 'The server, while acting as a gateway or proxy, did not receive a timely response from an upstream server it needed to access in order to complete the request.',
      },
      505: {
        header: 'HTTP version not supported',
        body: 'The server does not support, or refuses to support, the major version of HTTP that was used in the request message.',
      },
    },
    appearance: {
      //     {t('appearance.')}
      theme: 'Theme',
      displayMessage: 'Show the message',
      hideMessage: 'Hide message',
      validation: {
        invalidImageType:
          'Invalid image format. Allowed types are: {{allowed}}, format provided {{provided}}. ',
        maxImageSize: 'Image size is larger than allowed {{value}}',
        minImageSize: '',
        maxImageWidth: 'The image width should not be larger than {{value}}',
        minImageWidth: 'The image width should not be smaller than {{value}}',
        minImageHeight: 'The image height should not be less than {{value}}',
        maxImageHeight: 'The image height should not be greater than {{value}}',
      },
    },
    auth: {
      // {t('auth.')}
      logInNow: 'Log in now',
      registration: {
        submitRegistration: 'Submit registration',
        registerInDomain: 'Register in the login area.',
      },
      changePassword: 'Change Password',
      restorePassword: 'Password reset',
      passwordResetedSuccessfully: 'Your password has been successfully reset.',
      confirmEmail: 'Confirmation e-mail',
      login: 'Login',
      register: 'To register',
      registerInAdonceForFree: 'Register now for free with AdOnce!',
      iAcceptThis: 'I hereby accept that',
      and: 'and the',
      yeewGMBH: 'Yeew GmbH',

      areYouRegistered: ' Are you already registered with AdOnce? Then you can contact us directly',
      loginInNow: 'Log in now',
      linkSent: 'SEND LINK',
      checkYourEmailBox: 'Check mailbox',
      forgetPassword: 'Forgot Password?',
      registerForFree: 'Register for free',
      registerHereForFree: 'Register here for free!',
      // register: 'Зареєструйтеся в області входу.',
      passwordChanedDescription:
        'The password reset email has been sent. Please check your inbox and click on the link in the email.',
      registrationDescription:
        'Enter the email address you used to create your account. We will send you a link to reset your password.',
      registeredSuccessfully:
        'Confirmation email has been sent. You must verify your email to gain access to the system',
      emailConfirmed: 'Email confirmed',
      emailConfirmedDescription: 'Your email has been confirmed and you can now log in',
      invalidLink: 'Invalid link',
      invalidLinkDescription:
        'This link is no longer valid, please go to the login page and try to log in',
      emailConfirmation: 'E-mail confirmation',
      confirmationIsSent: 'Confirmation email has been sent',
      sendConfirmationAgain: 'Resend confirmation',
      requestingConfirmation: 'Email confirmation link is requested',
      pleaseWait: 'Please wait...',
      errorCodes: {
        '2001': 'User is locked. To unlock, contact admin.',
        '2004':
          'You must confirm your email to gain access to the system. Please follow the link in the email received in your inbox. <a href="/new/auth/confirm-email/request-token/{{email}}">Resend confirmation email</a>',
        '2002': 'Email or password is invalid.',
      },
      validation: {
        emailInvalid: 'Please enter a valid email address',
        passwordsDoNotMatch: 'Passwords do not match',
        emailsDoNotMatch: "Emails don't match",
        passwordIsShort: 'The password is too short',
        passwordIsLong: 'The password is too long',
        email: 'Please enter a valid email address',
        emailIsRequired: 'Email is required',
      },
      fields: {
        name: 'Name',
        email: 'E-mail',
        password: 'Password',
        repeatEmail: 'Confirm email address',
        repeatPassword: 'Confirm Password',
      },
    },
    common: {
      reportButton: 'REPORT PROBLEM',
      poweredBy: 'Powered by AdOnce Message',
      uploadLogoImage: 'Upload logo',
      rowsPerPage: 'Lines per page',
      noRowsToDisplay: 'There are no rows to display',
      searchBy: 'Search for',
      save: 'Save',
      saveChanges: 'save Changes',
      cancel: 'Cancel',
      delete: 'Delete',
      snackbars: {
        deletedSuccessfully: '',
        savedSuccessfully: 'Saved successfully',
        errorOccurred: 'An error has occurred',
      },
      userStatus: {
        active: 'Active',
        notVerified: 'Not verified',
      },
      gender: {
        male: 'Mr.',
        female: 'Mrs.',
        gender: 'Gender',
        all: 'All',
        men: 'Men',
        women: 'Women',
      },
      age: {
        age: 'Age',
      },
      validation: {
        required: '{{field}} is required',
        isRequired: '\n' +
            'necessary',
        positive: '{{field}} should be positive',
        minValue: 'Should be equal to or greater than {{minValue}} be',
        maxValue: 'Must be equal to or less than {{maxValue}}',
        lengthMin: 'This field must be at least {{length}} Characters must be long',
        lengthMax: 'This field can be a maximum of {{length}} characters long',
        invalidTypeProvided: 'Invalid file type specified: {{provided}}, are allowed types: {{allowed}}',
        invalidMaxFileSizeProvided:
          'The file size should not be larger than {{allowed}}, {{provided}} provided',
        locationsWereNotProvided: 'Please select address, zip code, city or region.',
      },
    },
    clients: {
      client: 'Client',
      clients: 'Clients',
      editClient: 'Edit Client',
      createClient: 'Create new client',
      createClientPageHeader: 'New client',
      returnToAllClients: 'Back to all clients',
      chooseFile: 'Choose file',
      saveToHavePreview: 'Save changes to get a file preview',
      fields: {
        countryCode: 'Country',
        zip: 'Postcode',
        city: 'City',
        address: 'Street, house number',
        phone: 'Telephone number',
        googleAdsCustomerId: 'Google Ads ID',
        registrationNumber: 'Registration number',
        tradeLicenceNumber: 'Business license number',
        vatNumber: 'VAT number',
        dunsNumber: 'Duns number',
        name: 'Company',
        email: 'E-mail',
        createdOn: 'Created on',
        externalId: 'Customer number',
      },
      validation: {
        dunsNumber: 'Only digits allowed for DUNS number',
        name: 'Company is required',
        phone: 'Phone number is required',
        phoneInvalid: 'The phone number should consist of numbers',
        emailInvalid: 'Please enter a valid email address',
        externalId: 'Customer number',
      },
    },
    tenants: {
      allTenants: 'Filter by tenant',
      firstAdmin: 'First Admin',
      firstAdminDescription:
        'Please add the details of the user to be created as the first administrator of this new tenant. This user must accept the invitation and confirm their email address before they can access the tool with administrative rights',
      selectBrand:
        'Choose which brand name to use across platforms and which color theme to use',
      customization: 'Adjustment',
      customTheme: 'Custom theme',
      adonceColors: 'AdOnce colors',
      backToAllTenants: 'Back to all tenants',
      customLogoHeading: 'Brand to use',
      defaultLogo: 'AdOnce',
      customLogo: 'Own brand',
      setAdServer: 'Set up ad servers',
      setAdServerFor: 'Set up ad servers for',
      setAdServerDescription:
          '\n' +
          'This setup can only be changed during tenant creation.',
      setAdServerNotes: 'Please note that ad servers can only be selected once. After you create a tenant, you cannot change the ad servers.',
      createTenant: 'Create new tenant',
      createTenantPageHeader: 'New Tenant',
      editTenant: 'Details',
      returnToTenants: 'Back to all tenants',
      deleteTenant: 'Delete tenant',
      deleteWarningText: 'Are you sure you want to delete ',
      deletedSuccessfully: '"{{name}}" Tenant successfully deleted',
      adServers: {
        nativeAds: 'Native Ads',
        displayAds: 'Display Ads',
      },
      budgets: {
        tenant: 'Tenant',
        pageHeader: 'Set up budget',
        googleMarginDescriptionCaption:
          'Set the AdOnce and partner margin for each campaign budget range.',
        googleImpessionDescriptionCaption:
          'Set 0 target impressions for the budget range you want to manually update in Google Ads.',
        SASubheaderCaption: 'Set the AdOnce and partner margin for each campaign budget range.',
        campaignsWithEndDate: 'Campaigns with an end date',
        campaignsWithoutEndDate: 'Campaigns without an end date',
        totalBudget: 'Total budget',
        adonceMargin: 'Margin from AdOnce and partners',
        targetImpressions: 'Target impressions',
        adonceCPMMargin: 'AdOnce CPM margin',
        addBudgetRange: 'Add budget range',
        cannotAddMoreBudgets: 'The table can be a maximum {{max}} Budgets included',
        budgetAlreadyExists: 'This budget already exists',
        targetImpressionsMaxValueError: 'This field can be a maximum of 12 characters long',
        deleteBudgetHeader: 'Delete budget area?',
        deleteBudgetBody:
          "Do you want the budget range  '{{name}}' and really delete its settings? This is irreversible.",
        cancel: 'Cancel',
        deleteBudget: 'Delete budget',
        failedToLoad: 'Tenant Target Impressions could not be loaded',
        optimization: {
          'CPC (enhanced)': 'CPC (enhanced)',
          'Maximize clicks': 'Maximize clicks',
          'Maximize conversions': 'Maximize conversions',
          'Viewable CPM': 'Viewable CPM',
          title: 'Optimization',
          description:
            'Set default bidding strategies values. Bidding strategy value can be edited during campaign creation.',
          default: 'Default',
          strategy: 'Bidding Strategy',
          value: 'Value',
          cpcParameter: 'Max. CPC',
          cpmParameter: 'Max. CPM (Viewable)',
          conversionsParameter: 'Target CPA',
        },
      },
      listContextMenu: {
        editTenant: 'Details',
        copyCampaign: 'Copy campaign',
        customizeAppearance: 'Adjust appearance',
        setAdServer: 'Set up ad servers',
        deleteTenant: 'Delete tenant',
        budgetSetup: 'Set up budget',
      },
      fields: {
        id: 'ID',
        name: 'Name',
        email: 'E-mail',
        domain: 'Domain',
        googleId: 'Google ID',
        googleAdsCustomerId: 'Google Ads ID',
        countryCode: 'Country',
        zip: 'Postcode',
        city: 'City',
        address: 'Street, house number',
        phone: 'Telephone number',
        emailHelpText: 'Email alerts and reports are sent to this email address.',
      },
      validation: {
        domainShouldBeUnique: 'Domain should be unique',
        noAdServerSelected: 'At least one should be selected',
        name: 'Name is required',
        email: 'Email is required',
        city: 'City is required',
        phone: 'Phone number is required',
        phoneInvalid: 'The phone number should consist of numbers',
        googleAdsCustomerIdInvalidLength: 'The Google ID must be 10 digits long',
        googleAdsCustomerId: 'Google ID is required',
        googleAdsCustomerIdInvalid: 'The Google ID number should consist of numbers',
        address: 'Street house number is required',
        zipInvalid: 'Invalid zip code',
        emailInvalid: 'Please enter a valid email address',
        domain: 'Domain is required',
        domainInvalid: 'Please enter a valid domain',
      },
      tenants: 'Tenants',
    },
    profile: {
      changePassword: 'Change Password',
      deletedSuccessfully: 'Profile deleted successfully',
      returnToUsers: 'Back to users',
      editProfile: 'Edit profile',
      deleteProfile: 'Delete profile',
      deleteProfileText:
        'If you delete your profile, you will NOT be able to log in to the platform with the current user. All your personal data will be deleted. This action cannot be undone. Are you sure that you want to continue.',
      fields: {
        language: 'Language',
        name: 'Name',
        role: 'Role',
        email: 'E-mail',
        gender: 'Gender',
      },
      validation: {
        name: 'Name is required',
      },
    },
    users: {
      allUsers: 'All users',
      details: 'Details',
      list: {
        contextMenu: {
          areYouSureAboutBlocking: 'Are you sure about blocking',
          areYouSureAboutUnblocking: 'Are you sure about unblocking',
          unblock: 'Unblock',
          block: 'Block',
          blockUser: 'Block User',
          unblockUser: 'Unblock User',
          cancel: 'CANCEL',
        },
      },
      fields: {
        id: 'ID',
        name: 'Name',
        email: 'Email',
        role: 'Role',
        status: 'Status',
        blocked: 'Blocked',
        createdAt: 'Created On',
        gender: 'Gender',
      },
      validation: {
        genderIsRequired: 'Gender is required',
      },
    },
    tenantNotifications: {
      otherTenantSelected:
        'You are logged in to tenant {{ownTenantName}}, some actions in tenant {{tenantName}} are unavailable. Log in to ',
    },
    faq: {
      title: 'Frequently Asked Questions',
      accordions: {
        1: {
          topic: 'How do I create a new customer?',
          description:
            '<p>Navigate to "Clients" in the main menu. Click on "Create new client" in the upper right corner. The "Company" field is a mandatory field and must be filled out. All other fields are optional and are used for further assignment.</p>',
        },
        2: {
          topic: 'How do I create a campaign?',
          description:
            '<p>Navigate to list of campaigns in main menu. Press Create New Campaign button in the right top corner. On page for new campaign creation, setup first attributes of the campaigns and press save button in order to get access to targeting and ads setup tabs. Please, add to a campaign client record which represents an Advertisor entity. You can manage client records in separate list from main menu.</p><p>Start of campaign can be only a future date.You can create a campaign with limited duration or without end date. For open ended campaign, you would need to enter a total budget instead of daily.</p><p>You can save a campaign as a draft or you can submit the campaign if you consider it ready for being run. See details in Campaign Statuses FAQ section.</p>',
        },
        3: {
          topic: 'What formats and text lengths are used for the advertising materials?',
          description:
            '<p>For the Display Ad campaigns, the formats JPG, PNG, GIF and AMPHTML are each permitted in 300x250px and in the maximum size of 150kb.</p><p>For the Native Ad campaigns, the formats JPG, PNG and GIF are permitted in each case 300x300px and 600x314px in the maximum size of 150kb. The headline of the Native Ad campaigns may contain a maximum of 70 characters, the description 350 characters and the company name 25 characters.</p>',
        },
        4: {
          topic: 'What status can campaigns have and how can they be managed?',
          description:
            '<p>Each submitted campaign first carries the status "Ready" and becomes "Active" on the date of the entered campaign start if all advertising media comply with the requirements. The campaign automatically becomes "Completed" on the campaign end date or when the user manually ends the campaign.</p><p>You can also cancel any active campaign. This means that the campaign will be "Cancelled".</p><p>We stop placing ads in that campaign and the campaign is closed and you cannot reopen it. You can also "Pause" a campaign so that you can then restart it. During the pause, no ads from the campaign will be played.</p><p>The status " Failed " is rare and is given to a campaign if the ads it contains violate ethical rules (nudity, racism, etc.).</p><p>The user can edit the campaign details, targeting or ads if the campaign is not "Active", "Cancelled" or "Completed".</p>',
        },
        5: {
          topic: 'How do I manage campaigns of a single client?',
          description:
            '<p>To group all new and existing campaigns that belong to a single advertiser, each campaign should be assigned to a specific client record (this is set in the campaign card when creating or editing the campaign). You can create and manage a list of clients by navigating to the menu item "Clients" in the main menu.</p>',
        },
        6: {
          topic: 'How do I manage the users?',
          description:
            '<p>As an administrator, you can assign the same role to other people or remove/delete other users. You cannot create users, everyone should register on the login page and only after the user exists you can assign them a role so that this user have access to the system. Blocked users will not be able to log in and will receive a message about it. Users with the role "Administrator", can activate or deactivate the playout of each channel.</p>',
        },
        7: {
          topic: 'How can I change the data of my personal profile?',
          description:
            '<p>You can change your data (email, name, etc.) in the personal menu in the top right corner of each page. You can also change your password and delete your profile completely from the system (note that in this case you will lose your access to the system).</p>',
        },
        8: {
          topic: 'How do I see the campaign reports?',
          description:
            '<p>Simply navigate to any campaign and select "Download Report" from the campaign menu. The file will download as a PDF and will contain graphs of clicks, impressions and the click rate over time.</p>',
        },
        9: {
          topic: 'Can I customise the appearance of my area?',
          description:
            '<p>Yes, you can customise the look of the entire area for all your users. Simply contact your AdOnce representative to request this change.</p>',
        },
      },
    },
    campaigns: {
      newCampaignPageHeader: 'New campaign',
      allCampaigns: 'All campaigns',
      locationType: {
        specificArea: 'Specific area',
        radius: 'Radius',
      },
      list: {
        downloadReport: 'Download report',
        tableLabels: {
          id: 'ID',
          name: 'Campaign name',
          type: 'Type',
          client: 'Client',
          startDate: 'Start date',
          endDate: 'End date',
          specifyTime: 'Specify the start and end time',
          status: 'Status',
          totalBudget: '',
          createdAt: 'Created on',
        },
      },
      preview: {
        preview: 'Preview',
        previewIsNotAvailable: 'The AMPHTML preview is currently not available',
        selectDevice: 'Select device for preview',
        selectImageSize: 'Select the image size to preview',
        desktop: 'Desktop',
        mobile: 'Mobil',
        addHeadline: 'Add heading',
        addDescription: 'Add description',
        addBusinessName: 'Add company name',
        selectAmphtml: 'Select AMPHTML for preview',
        selectImages: 'Select Image for preview',
      },
      buttonNames: {
        createNewCampaign: 'Create new campaign',
        next: 'next',
        prev: 'Previous',
        prevOnLast: 'Back',
        completeAndView: 'Campaign summary',
        backToList: 'Back to all campaigns',
        copy: 'Copy',
        edit: 'Edit',
        publish: 'Publish',
        proceedPublish: 'I have it',
        delete: 'Delete',
        save: 'Save',
        discardChanges: 'Back to view',
        cancel: 'Cancel campaign',
        saveAndPublish: 'Save & publish',
        backToEditing: 'back to editing',
      },
      modals: {
        newClient: {
          createClientHeader: 'Create new client',
          noClientsFound: 'No customer found',
          tryAnotherName: 'Please try a different spelling, or',
          createNewClient: 'Create a new client',
          close: 'Close',
          save: 'Create',
        },
        leave: {
          header: 'Are you sure you\'re going? Changes you make will not be saved',
        },
        cancel: {
          header: 'Campaign is ready!',
          body: 'Your campaign will be validated and launched on the scheduled date.',
          proceedButton: 'I have it',
        },
        saveAndPublish: {
          header: 'Campaign is ready!',
          body: 'Your campaign will be validated and launched on the scheduled date.',
          proceedButton: 'Ok',
        },
        discard: {
          header: 'Back to view?',
          body: 'All changes will not be saved.',
          proceedButton: 'Discard',
        },
        publish: {
          header: 'Campaign is ready!',
          body: 'Your campaign will be validated and launched on the scheduled date.',
          proceedButton: 'Ok',
        },
        delete: {
          body: {
              areYouSure: 'Are you sure you want the campaign?',
            toCancel: 'really cancel?',
            thisCannotBeReverted: 'This cannot be undone.',
          },
        },
      },
      fields: {
        days: 'Days',
        hours: 'hours',
        client: 'Client',
        campaign: 'Campaign',
        status: 'Status',
        startDate: 'Start date',
        endDate: 'End date',
        startTime: 'Start time',
        endTime: 'End time',
        startEndDate: 'Start-end date',
        dailyBudget: 'Daily budget',
        totalBudget: 'Total budget',
        biddingStrategy: 'Bidding strategy',
        CurrentAI: 'Current AI',
        budget: 'Budget',
        duration: 'Length of time',
        scheduleAndBudget: 'Duration and budget',
        gender: 'Gender',
        name: 'Name',
        url: 'URL',
        headline: 'Headline',
        longHeadline: 'Headline',
        description: 'Description',
        businessName: 'Company name',
        linkedUrl: 'Target URL (Where should the advertisement link to?)',
      },
      validation: {
        cannotAddMoreLocations: 'You can\'t do more than {{locations}} Add locations',
        cannotAddMoreImages: 'Cannot add more than {{maximumImages}} images',
        useShortDateFormat: 'Invalid date. Please use dd–MM–yyyy format',
        startDateCannotBeEarlierThanTomorrow: 'Start date cannot be earlier than tomorrow',
        endDateCannotBeEarlierThanTomorrow: 'End date cannot be earlier than tomorrow',
        endDateCannotBeEarlierThanStartDate: 'End date cannot be earlier than start date',
        amphtmlShouldBeAdded: 'AMPHTML zip should be added',
        cannotAddMoreAmphtmlFiles: 'It is not allowed to upload more than {{maximumFiles}} Zip files',
        atLeastOneImageShouldBeSet:
          'In order to submit the campaign, at least one advertising material must first be uploaded',
        pointPositionShouldBeSet: 'The point position also needs to be set',
        locationsListShouldBeSet: 'List of locations needs to be set',
        totalBudgetRange: 'Daily budget from 4 to 4000',
        atLeastOneDeviceShouldBeChosen: 'At least one device type should be selected',
        campaignsNameIsTooLong: 'Campaign name is zoo long',
        campaignsNameIsRequired: 'Campaign name is required',
        invalidCoordinates: 'Set up latitude and longitude',
        protocol: 'URL should start with protocol: http:// oder https://',
        maximumImagesError: 'Images cannot be more than {{allowed}}',
        atLeastOneShouldBeSet: 'At least one {{resolution}} image should be adjusted',
        locationTypeNotSelected: 'Location type was not selected',
        locationsMaximumReached: 'Locations cannot be more than {{number}}',
        locationsCannotAddMore: "You can't do more than {{number}} Add locations",
        locationsWereNotProvided: 'The point location or a list of locations should be provided',
      },
      failedStatusDescription: {
        8000: 'The campaign failed verification. An unexpected error has occurred. Please contact your administrators or create a new campaign.',
        8101: 'The campaign failed verification. The location selected is not supported for targeting. Please review locations in targeting and resubmit the campaign.',
        8201: 'The campaign failed verification. {error message}',
        8202: 'The campaign failed verification. URL is not valid. Please review URL in adversting materials and resubmit the campaign.',
        8301: 'The campaign failed verification. Media file is not valid. Please review media in adversting materials and resubmit the campaign.',
        8302: 'The campaign failed verification. Animation has disallowed visual effects.',
      },
      adServerErrorDescription: {
        'Location is invalid.': 'Location is invalid.',
        'The image is not valid.': 'The image is not valid.',
        'The image could not be stored.': 'The image could not be stored.',
        'There was a problem with the request.': 'There was a problem with the request.',
        'The image is not of legal dimensions.': 'The image is not of legal dimensions.',
        'Animated image are not permitted.': 'Animated image are not permitted.',
        'Animation is too long.': 'Animation is too long.',
        'There was an error on the server.': 'There was an error on the server.',
        'Image cannot be in CMYK color format.': 'Image cannot be in CMYK color format.',
        'Unacceptable visual effects.': 'Unacceptable visual effects.',
        'Incorrect image layout.': 'Incorrect image layout.',
        'There was a problem reading the image file.': 'There was a problem reading the image file.',
        'There was an error storing the image.': 'There was an error storing the image.',
        'The aspect ratio of the image is not allowed.': 'The aspect ratio of the image is not allowed.',
        'Image file is too large.': 'Image file is too large.',
        'Image data is too large.': 'Image data is too large.',
        'Error while processing the image.': 'Error while processing the image.',
        'Image is too small.': 'Image is too small.',
        'Input was invalid.': 'Input was invalid.',
        'Image format is not allowed.': 'Image format is not allowed.',
        'Google Ads API encountered unexpected internal error.':
          'Google Ads API encountered unexpected internal error.',
        "The intended error code doesn't exist in specified API version. It will be released in a future API version.":
          "The intended error code doesn't exist in specified API version. It will be released in a future API version.",
        'Google Ads API encountered an unexpected transient error. The user should retry their request in these cases.':
          'Google Ads API encountered an unexpected transient error. The user should retry their request in these cases.',
        'The request took longer than a deadline.': 'The request took longer than a deadline.',
        'Cannot create a standard icon type.': 'Cannot create a standard icon type.',
        'Image contains both a media file ID and data.': 'Image contains both a media file ID and data.',
        'A media file with given type and reference ID already exists.':
          'A media file with given type and reference ID already exists.',
        'A required field was not specified or is an empty string.':
          'A required field was not specified or is an empty string.',
        'The media file ID is invalid.': 'The media file ID is invalid.',
        'The media subtype is invalid.': 'The media subtype is invalid.',
        'The media file type is invalid.': 'The media file type is invalid.',
        'The mimetype is invalid.': 'The mimetype is invalid.',
        'The media reference ID is invalid.': 'The media reference ID is invalid.',
        'Media file has failed transcoding': 'Media file has failed transcoding',
        'Media file has not been transcoded.': 'Media file has not been transcoded.',
        "The media type does not match the actual media file's type.":
          "The media type does not match the actual media file's type.",
        'The string has too many characters.': 'The string has too many characters.',
        'The specified type is not supported.': 'The specified type is not supported.',
        'The uploaded file is too big.': 'The uploaded file is too big.',
        'Image data is unparseable.': 'Image data is unparseable.',
        'Animated images are not allowed.': 'Animated images are not allowed.',
        'The image or media bundle format is not allowed.': 'The image or media bundle format is not allowed.',
        'Cannot reference URL external to the media bundle.': 'Cannot reference URL external to the media bundle.',
        'HTML5 ad is trying to reference an asset not in .ZIP file.':
          'HTML5 ad is trying to reference an asset not in .ZIP file.',
        'The media bundle contains no primary entry.': 'The media bundle contains no primary entry.',
        'Animation has disallowed visual effects.': 'Animation has disallowed visual effects.',
        'Animation longer than the allowed 30 second limit.': 'Animation longer than the allowed 30 second limit.',
        'The aspect ratio of the image does not match the expected aspect ratios provided in the asset spec.':
          'The aspect ratio of the image does not match the expected aspect ratios provided in the asset spec.',
        'Audio files are not allowed in bundle.': 'Audio files are not allowed in bundle.',
        'CMYK jpegs are not supported.': 'CMYK jpegs are not supported.',
        'Flash movies are not allowed.': 'Flash movies are not allowed.',
        'The frame rate of the video is higher than the allowed 5fps.':
          'The frame rate of the video is higher than the allowed 5fps.',
        'ZIP file from Google Web Designer is not published.': 'ZIP file from Google Web Designer is not published.',
        'Image constraints are violated, but more details (like DIMENSIONS_NOT_ALLOWED or ASPECT_RATIO_NOT_ALLOWED) can not be provided. This happens when asset spec contains more than one constraint and criteria of different constraints are violated.':
          'Image constraints are violated, but more details (like DIMENSIONS_NOT_ALLOWED or ASPECT_RATIO_NOT_ALLOWED) can not be provided. This happens when asset spec contains more than one constraint and criteria of different constraints are violated.',
        'Media bundle data is unrecognizable.': 'Media bundle data is unrecognizable.',
        'There was a problem with one or more of the media bundle entries.':
          'There was a problem with one or more of the media bundle entries.',
        'The asset has an invalid mime type.': 'The asset has an invalid mime type.',
        'The media bundle contains an invalid asset path.': 'The media bundle contains an invalid asset path.',
        'Image has layout problem.': 'Image has layout problem.',
        'An asset had a URL reference that is malformed per RFC 1738 convention.':
          'An asset had a URL reference that is malformed per RFC 1738 convention.',
        'The uploaded media bundle format is not allowed.': 'The uploaded media bundle format is not allowed.',
        'The media bundle is not compatible with the asset spec product type. (For example, Gmail, dynamic remarketing, etc.)':
          'The media bundle is not compatible with the asset spec product type. (For example, Gmail, dynamic remarketing, etc.)',
        'A bundle being uploaded that is incompatible with multiple assets for different reasons.':
          'A bundle being uploaded that is incompatible with multiple assets for different reasons.',
        'The media bundle contains too many files.': 'The media bundle contains too many files.',
        'Google Web Designer not created for "Google Ads" environment.':
          'Google Web Designer not created for "Google Ads" environment.',
        'Unsupported HTML5 feature in HTML5 asset.': 'Unsupported HTML5 feature in HTML5 asset.',
        'URL in HTML5 entry is not SSL compliant.': 'URL in HTML5 entry is not SSL compliant.',
        'Video file name is longer than the 50 allowed characters.':
          'Video file name is longer than the 50 allowed characters.',
        'Multiple videos with same name in a bundle.': 'Multiple videos with same name in a bundle.',
        'Videos are not allowed in media bundle.': 'Videos are not allowed in media bundle.',
        'This type of media cannot be uploaded through the Google Ads API.':
          'This type of media cannot be uploaded through the Google Ads API.',
        'The dimensions of the image are not allowed.': 'The dimensions of the image are not allowed.',
        'The resource has been disapproved since the policy summary includes policy topics of type PROHIBITED.':
          'The resource has been disapproved since the policy summary includes policy topics of type PROHIBITED.',
        'The given policy topic does not exist.': 'The given policy topic does not exist.',
        'Ignorable policy topics are not supported for the ad type.':
          'Ignorable policy topics are not supported for the ad type.',
        'Exempt policy violation keys are not supported for the ad type.':
          'Exempt policy violation keys are not supported for the ad type.',
        'Cannot set ignorable policy topics and exempt policy violation keys in the same policy violation parameter.':
          'Cannot set ignorable policy topics and exempt policy violation keys in the same policy violation parameter.',
        'A policy was violated. See PolicyViolationDetails for more detail.':
          'A policy was violated. See PolicyViolationDetails for more detail.',
        'The input string value contains disallowed characters.':
          'The input string value contains disallowed characters.',
        'The input string value is invalid for the associated field.':
          'The input string value is invalid for the associated field.',
        'The specified field should have a least one non-whitespace character in it.':
          'The specified field should have a least one non-whitespace character in it.',
        'Too short.': 'Too short.',
        'Too long.': 'Too long.',
        'The tracking url template is invalid.': 'The tracking url template is invalid.',
        'The tracking url template contains invalid tag.': 'The tracking url template contains invalid tag.',
        'The tracking url template must contain at least one tag (for example, {lpurl}), This applies only to tracking url template associated with website ads or product ads.':
          'The tracking url template must contain at least one tag (for example, {lpurl}), This applies only to tracking url template associated with website ads or product ads.',
        'The tracking url template must start with a valid protocol (or lpurl tag).':
          'The tracking url template must start with a valid protocol (or lpurl tag).',
        'The tracking url template starts with an invalid protocol.':
          'The tracking url template starts with an invalid protocol.',
        'The tracking url template contains illegal characters.':
          'The tracking url template contains illegal characters.',
        'The tracking url template must contain a host name (or lpurl tag).':
          'The tracking url template must contain a host name (or lpurl tag).',
        'The tracking url template has an invalid or missing top level domain extension.':
          'The tracking url template has an invalid or missing top level domain extension.',
        'The tracking url template contains nested occurrences of the same conditional tag (for example, {ifmobile:{ifmobile:x}}).':
          'The tracking url template contains nested occurrences of the same conditional tag (for example, {ifmobile:{ifmobile:x}}).',
        'The final url is invalid.': 'The final url is invalid.',
        'The final url contains invalid tag.': 'The final url contains invalid tag.',
        'The final url contains nested occurrences of the same conditional tag (for example, {ifmobile:{ifmobile:x}}).':
          'The final url contains nested occurrences of the same conditional tag (for example, {ifmobile:{ifmobile:x}}).',
        'The final url must start with a valid protocol.': 'The final url must start with a valid protocol.',
        'The final url starts with an invalid protocol.': 'The final url starts with an invalid protocol.',
        'The final url contains illegal characters.': 'The final url contains illegal characters.',
        'The final url must contain a host name.': 'The final url must contain a host name.',
        'The final mobile url is invalid.': 'The final mobile url is invalid.',
        'The final mobile url contains invalid tag.': 'The final mobile url contains invalid tag.',
        'The final mobile url contains nested occurrences of the same conditional tag (for example, {ifmobile:{ifmobile:x}}).':
          'The final mobile url contains nested occurrences of the same conditional tag (for example, {ifmobile:{ifmobile:x}}).',
        'The final mobile url must start with a valid protocol.':
          'The final mobile url must start with a valid protocol.',
        'The final mobile url starts with an invalid protocol.':
          'The final mobile url starts with an invalid protocol.',
        'The final mobile url contains illegal characters.': 'The final mobile url contains illegal characters.',
        'The final mobile url must contain a host name.': 'The final mobile url must contain a host name.',
        'The final app url is invalid.': 'The final app url is invalid.',
        'The final app url contains invalid tag.': 'The final app url contains invalid tag.',
        'The final app url contains nested occurrences of the same conditional tag (for example, {ifmobile:{ifmobile:x}}).':
          'The final app url contains nested occurrences of the same conditional tag (for example, {ifmobile:{ifmobile:x}}).',
        'More than one app url found for the same OS type.': 'More than one app url found for the same OS type.',
        'The OS type given for an app url is not valid.': 'The OS type given for an app url is not valid.',
        'The protocol given for an app url is not valid. (For example, "android-app")':
          'The protocol given for an app url is not valid. (For example, "android-app://")',
        'The package id (app id) given for an app url is not valid.':
          'The package id (app id) given for an app url is not valid.',
        'The number of url custom parameters for an resource exceeds the maximum limit allowed.':
          'The number of url custom parameters for an resource exceeds the maximum limit allowed.',
        'An invalid character appears in the parameter key.': 'An invalid character appears in the parameter key.',
        'An invalid character appears in the parameter value.': 'An invalid character appears in the parameter value.',
        'The url custom parameter value fails url tag validation.':
          'The url custom parameter value fails url tag validation.',
        'The custom parameter contains nested occurrences of the same conditional tag (for example, {ifmobile:{ifmobile:x}}).':
          'The custom parameter contains nested occurrences of the same conditional tag (for example, {ifmobile:{ifmobile:x}}).',
        'The protocol (http or https) is missing.': 'The protocol (http:// or https://) is missing.',
        'Unsupported protocol in URL. Only http and https are supported.':
          'Unsupported protocol in URL. Only http and https are supported.',
        'The url is invalid.': 'The url is invalid.',
        'Destination Url is deprecated.': 'Destination Url is deprecated.',
        'The url contains invalid tag.': 'The url contains invalid tag.',
        'The url must contain at least one tag (for example, {lpurl}).':
          'The url must contain at least one tag (for example, {lpurl}).',
        'Duplicate url id.': 'Duplicate url id.',
        'Invalid url id.': 'Invalid url id.',
        "The final url suffix cannot begin with '?' or '&' characters and must be a valid query string.":
          "The final url suffix cannot begin with '?' or '&' characters and must be a valid query string.",
        'The final url suffix cannot contain {lpurl} related or {ignore} tags.':
          'The final url suffix cannot contain {lpurl} related or {ignore} tags.',
        'The top level domain is invalid, for example, not a public top level domain listed in publicsuffix.org.':
          'The top level domain is invalid, for example, not a public top level domain listed in publicsuffix.org.',
        'Malformed top level domain in URL.': 'Malformed top level domain in URL.',
        'Malformed URL.': 'Malformed URL.',
        'No host found in URL.': 'No host found in URL.',
        'Custom parameter value cannot be null.': 'Custom parameter value cannot be null.',
        'Track parameter is not supported.': 'Track parameter is not supported.',
      },
      status: {
        draft: 'Draft',
        ready: 'Ready',
        active: 'Active',
        // paused: 'Pausiert',
        canceled: 'Canceled',
        failed: 'Failed',
        completed: 'Completed',
        inReview: 'In Review',
      },
      clearAll: 'Delete everything',
      bannerAdMedia: 'Advertising Materials',
      locationHeading: 'Geo targeting',
      campaignName: 'Campaign name',
      campaignId: 'Campaign ID',
      adTab: 'Advertising Materials',
      targetingTag: 'Targeting',
      createCampaign: 'Create a new campaign',
      advertiseInZones: 'Advertise in specific zip codes, cities or regions',
      campaignsAttributeTab: 'CAMPAIGN ATTRIBUTES',
      amphtmlDescription:
        'You can upload up to 10 ZIP files. A ZIP file must contain a single HTML document and up to 39 pieces of media.',
      logo: 'Logo',
      generateReport: 'Create a report',
      generateReportCaption: 'Please wait, report is being generated',
      generalAttributes: 'General attributes',
      setCampaignAttributes: 'Set campaign attributes',
      optimization: 'Optimization',
      targetingSettings: 'Targeting',
      adSettings: 'Ad settings',
      googleAdsServerOptimization: 'Google Ads Server optimization',
      biddingStrategy: 'Bidding strategy',
      adType: 'Campaign type',
      nearAddress: 'Advertise within the radius of an address',
      specificAddress: 'Advertise in specific zip codes, cities or regions',
      targetingHeading: 'Targeting',
      address: 'Zip code, city and/or region',
      devices: 'Target devices',
      desktop: 'Desktop',
      mobile: 'Mobile (Smartphones und Tablet)',
      linkedUrl: 'Target URL (Where should the advertisement link to?)',
      adMediaDesc:
        'Please upload at least one advertising medium (Medium Rectangle, 300x250 pixels). Valid file formats: png, jpg, gif.',
      imageSize: 'Medium Rectangle',
      uploadImage: 'Upload advertising material',
      removeImage: 'Remove advertising materials',
      searchPlaces: 'City, zip code or region ...',
      adSettingsDescription: 'Advertising material settings',
      allStatuses: 'Filter by status',
      types: {
        banner: 'Display',
        native: 'Native',
      },
      typesDescription: {
        native1:
          'The advertising material is automatically adapted to the respective design of the websites on which it is displayed.',
        native2:
          'At first glance, the advertising is perceived as content, which significantly increases the user\'s attention.',
        native3: 'Excellent for both smartphones and desktop.',
        native4: 'Targeting options: location and devices.',
        banner1:
          'A medium rectangle with 300x250 pixels is used as an advertising medium, which is displayed equally on all websites.',
        banner2:
          'This banner size is ideal for smartphones, but can also be used well on desktops.',
        banner3: 'Targeting options: age, gender, location and devices.',
      },
      adMedia: 'Advertising material – display ad',
      adMediaDescription:
        'The more images provided, the more variations of your ad that can be displayed.',
      adMediaNativeDescriptions:
        'This feature generates ads in native formats to expand your reach to more publishers, including YouTube and Gmail. Adding native formats can also improve ad performance.',
        adText: 'Ad text',
      addAdAssets: 'Advertising materials',
      next: 'next',
      prev: 'Previous',
      prevOnLast: 'Back',
      summary: 'Campaign summary',
      marketingImages: 'Marketing images',
      headline: 'headline',
      description: 'Description',
      businessName: 'Company name',
      optimizationBiddingValidationMessage: 'Bidding strategy is required field',
      ageRangeValidationMessage: 'You must select at least one age range',
      imageResolutionError: 'Image does not meet requirements. The resolution should be one of the following', // 'Image does not match the requirements. Resolution should be one of the following',
      adTypeValidationMessage: `Should be one of the following: ${_.capitalize(TCampaignType.banner)}, ${_.capitalize(
        TCampaignType.native.toUpperCase()
      )}`,
      incorrectUrlValidationMessage: 'Please enter a valid URL',
      statusDescription: {
        draft:
          'The campaign record will be created but will not start until you submit it with mandatory fields filled out.',
        ready:
          'The campaign is created and starts automatically on the scheduled date.\n' +
          '\n' +
          'You can still modify the campaign and resubmit it.',
        active: 'Campaign is currently running.\n' + '\n' + 'You can still modify the campaign and resubmit it.',
        paused: 'The campaign has been paused. You can continue it before the end date',
        canceled: 'The campaign was canceled manually.',
        completed: 'Campaign completes according to end date.',
        inReview:
          'The campaign for one of the channels is currently under review. It is currently not running and will resume once the review is complete. No action is required.',
        failed:
          'The campaign failed verification. Make sure all attributes and files meet guidelines and resubmit the campaign.',
      },
      performance: {
        name: 'Campaign performance',
        activeUpdated: 'Campaign performance data is updated daily at 00:01 {{gmt}}. Last updated on {{date}}.',
        readyUpdated: 'Campaign performance data is updated daily at 00:01 {{gmt}} after the campaign starts.',
        completedUpdated: 'Last updated on {{date}}.',
        popup:
          'To improve campaign results, try to: refresh ad creatives, refine GEO and audience targeting parameters.',
        currentImpressions: 'Current impressions',
        clicks: 'Clicks',
        clickRate: 'Clickrate',
        downloadReport: 'Download report',
      },
      note: {
        note: 'Note',
        save: 'save',
        cancel: 'cancel',
        editNote: 'Edit note',
        addNote: 'Add note',
        question: 'Are you sure ?',
      },
      optimizationBidding: {
        bidStrategy: 'Choose a bidding strategy',
        bidValue: 'Optimization strategy coefficient',
        cpc: 'CPC (enhanced)',
        cpm: 'Viewable CPM',
        maxClicks: 'Maximize clicks',
        conversion: 'Maximize conversions',
        labelMaxCpc: 'Max. CPC',
        labelMaxCpm: 'Max. CPM (Viewable)',
        labelTargetCpa: 'Target CPA',
      },
    },
    reportMail: {
      body: 'Issue with {{campaignName}} {{campaignLink}} {{tenantName}}%0ADetails:',
      tenantInfo: 'for the Tenant {{tenantName}}',
      subject: 'Issue with {{campaignName}} {{tenantName}}',
    },
    help: {
      title: 'Frequently asked Questions',
      accordions: {
        1: {
          topic: 'How do I create a new customer?',
          description:
            '<ol><li>Navigate to "Clients" in the main menu.</li><li>Click on <strong>"Create new client"</strong> in the upper right corner.<ul><li> "Company" field is a mandatory field and must be filled out. All other fields are optional and are used for further assignment.</li></ul></li></ol>',
        },
        2: {
          topic: 'How to create a new campaign?',
          description:
              '<ol><li>Navigate to list of campaigns in main menu.</li><li>Press <strong>Create New Campaign</strong> button in the right top corner.<ul><li>On page for new campaign creation, setup first attributes of the campaigns and press save button in order to get access to targeting and ads setup tabs. </li><li>Please, add to a campaign client record which represents an Advertisor entity. You can manage client records in separate list from main menu.</li></ul></li><li>You can  <strong>Save</strong>, a campaign as a draft or you can submit the campaign if you consider it ready for being run.<ul><li><strong>Note:</strong> Start of campaign can be only a future date.</li></ul></li><li>See details in Campaign Statuses FAQ section.</li></ol>',
        },
        3: {
          topic: 'What formats and text lengths are used for the advertising materials?',
          description:
            '<p>For the <strong>Display Ad campaigns:</strong></p><ul><li>the formats JPG, PNG, GIF and AMPHTML are each permitted in 300x250px and in the maximum size of 150kb.</li></ul><p>For the <strong>Native Ad campaigns:</strong></p><ul><li> the formats JPG, PNG and GIF are permitted in each case 300x300px and 600x314px in the maximum size of 150kb. </li><li>The headline of the Native Ad campaigns may contain a maximum of 70 characters, the description 350 characters and the company name 25 characters. </li></ul></li></ul></li></ul></li></ul><p> GIFs can be animated, but the length must be under 30 seconds; it can be looped, but must stop after 30 seconds; must be slower than 5 FPS). </p><p> URL destination should be valid, reachable and should not return any error codes or require authorization. Redirection (including redirection from public domain to public subdomain) is not allowed. </p>',
        },
        4: {
          topic: 'What status can campaigns have and how can they be managed?',
          description:
            '<ul><li>Each submitted campaign first carries the status "Ready" and becomes "Active" on the date of the entered campaign start if all advertising media comply with the requirements.</li><li>The campaign automatically becomes "Completed" on the campaign end date or when the user manually ends the campaign. </li><li>You can also cancel any active campaign. This means that the campaign will be "Cancelled". We stop placing ads in that campaign and the campaign is closed and you cannot reopen it.</li><li>The status " Failed " is rare and is given to a campaign if the ads it contains violate ethical rules (nudity, racism, etc.).<br><strong>Index:</strong> The user can edit the campaign details, targeting or ads if the campaign is not "Active", "Cancelled" or "Completed".</li><li>In some extrem cases Ad Servers where the campaign will be distributed may require additional validation of content which may take up to several days. It is extremly rare cases. During these reviews campaigns get status In Review automatically and automatically returned to Active when review is done. When campaign is in review it is not being distributed to Ad Servers..</li></ul>',
        },
        5: {
          topic: 'How do I manage campaigns of a single client?',
          description:
            '<ul><li>To group all new and existing campaigns that belong to a single advertiser, each campaign should be assigned to a specific client record (this is set in the campaign card when creating or editing the campaign).</li><li> You can create and manage a list of clients by navigating to the menu item  <strong>"Clients"</strong> in the main menu.</li></ul>',
        },
        6: {
          topic: 'How do I manage the users?',
          description:
            '<ul><li>As an administrator, you can assign the same role to other people or remove/delete other users.</li><li>You cannot create users, everyone should register on the login page and only after the user exists you can assign them a role so that this user have access to the system.</li><li>Blocked users will not be able to log in and will receive a message about it.</li><li>Users with the role "Administrator", can activate or deactivate the playout of each channel.</li></ul>',
        },
        7: {
          topic: 'How can I change the data of my personal profile?',
          description:
            '<ul><li>You can change your data (email, name, etc.) in the personal menu in the top right corner of each page. </li><li>You can also change your password and delete your profile completely from the system (note that in this case you will lose your access to the system).</li></ul>',
        },
        8: {
          topic: 'How do I see the campaign reports?',
          description:
            '<p>Simply navigate to any campaign and select  <strong>"Download Report"</strong>from the campaign menu. The file will be downloaded as a PDF and will contain graphs of clicks, impressions and the click rate over time.</p>',
        },
        9: {
          topic: 'Can I customise the appearance of my area?',
          description:
            '<p>Yes, you can customise the look of the entire area for all your users. Simply contact your AdOnce representative to request this change.</p>',
        },
      },
    },
    subscriptions: {
      subscription: 'Subscription',
      create: {
        specifyIfCurrentCampaignInPartOfSubs: 'Specify if current campaign belongs to Subscription.',
        subscriptionCampaign: 'Subscription campaign',
        previousCampaign: 'Previous campaign',
        newSubscription: 'New Subscription',
        selectAPreviousCampaignFromList:
          'Select a previous campaign from current subscriptions or create a New Subscription.',
        campaignDurationRecomentation:
          'To achieve better results recommended campaign duration must be at least 1 month.',
      },
      view: {
        campaignIsPartOfSubscription: 'This campaign is part of a subscription.',
        previousCampaign: 'Previous campaign',
        none: 'None',
        campaignIsNotAPartOfSubscription: 'This campaign is not part of any subscription',
      },
      modals: {
        delete: {
          header: 'Delete campaign?',
          campaignDeleteWarning1: 'Are you sure you ',
          campaignDeleteWarning2: 'want to delete campaign ',
          campaignDeleteWarning3: 'This action can not be undone.',
          nextCampaign: 'The next campaign',
          willBeLinkedTo: 'in subscription will be linked to the previous campaign',
          to: '.',
        },
      },
      buttons: {
        cancel: 'Back',
        delete: 'Delete campaign',
      },
      tables: {
        inSubscription: 'Subscribed',
        notInSubscription: 'Not subscribed',
        all: 'All',
        hintText: 'This campaign comes with a subscription',
        sub: 'Subscription',
      },
    },
  },
}
